import Page from "Layout/Page";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import BillingPredictionFilters, {
  BillingPredictionFilters as FilterType,
  useBillingPredictionFilters,
} from "./BillingPredictionFilters";
import { COCM_BILLING_PREDICTION_STORAGE_KEY } from "Shared/Storage";
import { Badge, Paper, Typography, styled } from "@mui/material";
import Link from "MDS/Link";
import {
  BillingPredictionQuery,
  BillingPredictionQueryVariables,
  CocmBillingAlgorithmRuleCheckValue,
  EnrollmentMonthBillingRuleResultWinnerSortParameter,
  IntegerRange,
  SortDirection,
  useBillingPredictionQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { usePanelFilter } from "Contexts/SelectedPanelContext";
import { usePatientLinkUrl } from "FeedbackReport/Demographics/DemographicInfo";
import { formatMoney } from "Shared/Money";
import {
  BillableUnitExplanation,
  FailReasonsExplanation,
  PowerBar,
  billableUnitExplanationText,
  failReasonExplanationText,
} from "./BillingRuleHelpers";
import { formatNumber } from "Shared/formatters";
import { GridColumnGroupingModel, useGridApiRef } from "@mui/x-data-grid";
import { useQueryStringIdParameter } from "Shared/QueryStringParameter";
import { CocmBillingAlgorithmRuleId, EnrollmentMonthBillingRuleResultWinnerId } from "Lib/Ids";
import BillingWinnerDetailsModal from "./BillingWinnerDetailsModal";
import { PatientHeaderStatus } from "CollaborativeCare/PatientReference/PatientHeaderStatus";
import { PatientBillableMinutesWithTargetBadgeElement } from "CollaborativeCare/PatientBillableMinutesBadge";
import { useTestPatientViewability } from "Contexts/TestPatientViewabilityContext";
import { useDebounce } from "Lib/Hooks";
import { useConfiguration } from "Contexts/CurrentInstituteContext";
import { useProviderUserHasAnyRole } from "Shared/WithPermission";
import { ORDERED_VALUE_TYPES } from "./BillingDashboard";
import { formatMinutes } from "Shared/MinutesLabel";

// We use this map to switch out the different versions of the values
const VALUE_COLUMN_VALENCE: Record<string, CocmBillingAlgorithmRuleCheckValue> = {
  winningResultRvus: CocmBillingAlgorithmRuleCheckValue.RVUS,
  nextResultRvus: CocmBillingAlgorithmRuleCheckValue.RVUS,
  winningResultValueUnits: CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS,
  winningResultBillableMinutes: CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES,
  winningResultEstimatedRate: CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE,
  nextResultValueUnits: CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS,
  nextResultEstimatedRate: CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE,
  nextResultBillableMinutes: CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES,
  nextResultRateDiffVsCurrentWinner: CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE,
  nextResultRvuDiffVsCurrentWinner: CocmBillingAlgorithmRuleCheckValue.RVUS,
  nextResultValueUnitDiffVsCurrentWinner: CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS,
  nextResultBillableMinutesDiffVsCurretWinner: CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES,
  rvuPowerVsCurrentWinner: CocmBillingAlgorithmRuleCheckValue.RVUS,
  valueUnitPowerVsCurrentWinner: CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS,
  ratePowerVsCurrentWinner: CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE,
  billableMinutesPowerVsCurrentWinner: CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES,
  nextResultBillableMinutesDiffVsCurrentWinner: CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES,
};

export const ALL_VALUE_TYPES = [
  CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE,
  CocmBillingAlgorithmRuleCheckValue.RVUS,
  CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS,
  CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES,
];

export function BillingPredictionTable(props: {
  filters: FilterType;
  monthsofTreatment?: ReadonlyArray<IntegerRange> | null;
  winningRuleIds?: ReadonlyArray<CocmBillingAlgorithmRuleId> | null;
  allowedValueTypes: ReadonlyArray<CocmBillingAlgorithmRuleCheckValue>;
  // The view context allows us to use this table in different places that
  // each keep their own settings, e.g. in a small view and in a full page.
  viewContext: string;
  onRowClick?: (winnerId: EnrollmentMonthBillingRuleResultWinnerId) => void;
}) {
  const { t } = useTranslation(["billing", "common", "patients"]);
  const { filters, onRowClick, allowedValueTypes } = props;
  const panelFilter = usePanelFilter();

  const apiRef = useGridApiRef();

  // We don't want to reset the columns on every load, only when the user has selected something. So gate this and only set columns
  // when it has changed
  const [valueTypeHasChanged, setValueTypeHasChanged] = React.useState<boolean>(false);

  // This is used only in case of first time view and reset. The table keeps its setting appropriately otherwise.
  const columnVisibilityModel = React.useMemo(() => {
    return Object.entries(VALUE_COLUMN_VALENCE).reduce(
      (obj: Record<string, boolean>, [column, valence]) => {
        obj[column] = valence === filters.valueType;

        return obj;
      },
      {
        // You can make columns disappear by default here.
        mrn: false,
        dob: false,
        careManager: false,
        pcp: false,
        organization: false,
      }
    );
  }, [filters]);

  React.useEffect(() => {
    // The type system seems to believe this current hook cannot be null when in fact it definitely
    // can.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (apiRef.current && filters.valueType && valueTypeHasChanged) {
      Object.entries(VALUE_COLUMN_VALENCE).forEach(([column, valence]) => {
        apiRef.current.setColumnVisibility(column, valence === filters.valueType);
      });
    }
    // Note that this DOES NOT WORK in development as we double render everything. This means that the reset will be done every page load on
    // development builds.
    setValueTypeHasChanged(true);
  }, [filters.valueType]);

  const testPatient = useTestPatientViewability();
  const debouncedSearch = useDebounce(filters.search, 500);

  const queryVars: Pick<
    BillingPredictionQueryVariables,
    | "forPanel"
    | "month"
    | "search"
    | "withMonthOfTreatmentRange"
    | "withWinningRule"
    | "testPatient"
    | "availableForNode"
    | "stillEnrolled"
  > = {
    forPanel: panelFilter,
    month: filters.month,
    search: debouncedSearch,
    withMonthOfTreatmentRange: props.monthsofTreatment ?? null,
    withWinningRule: props.winningRuleIds ?? null,
    testPatient,
    availableForNode: props.filters.entityTreeNodeParams,
    stillEnrolled: props.filters.excludeDischarged,
  };

  const renderHeaderForGroup = (title: string) => () => {
    return <Typography fontWeight={"bold"}>{title}</Typography>;
  };

  const columns: DataGridCols<BillingPredictionQuery, ["billingEnrollmentMonthBillingRuleResultWinners"]> =
    React.useMemo(() => {
      const allCols: DataGridCols<
        BillingPredictionQuery,
        ["billingEnrollmentMonthBillingRuleResultWinners"]
      > = [
        // ====================================================================
        // Demographic details
        // ====================================================================
        {
          field: "patientStatus",
          headerName: t("billing:prediction.columns.patientStatus"),
          sortable: false,
          flex: 1,
          disableExport: true,
          renderCell: (params): ReactElement => {
            return (
              <PatientHeaderStatus
                patientName={params.row.enrollmentMonth.enrollment.careEpisode.patient.nameLastFirst}
                patientId={params.row.enrollmentMonth.enrollment.careEpisode.patient.id}
              />
            );
          },
        },
        {
          field: "patientName",
          headerName: t("billing:prediction.columns.patient"),
          sortable: true,
          flex: 2,
          valueGetter: (_value, row) => row.enrollmentMonth.enrollment.careEpisode.patient.nameLastFirst,
          renderCell: (params): ReactElement => {
            const url = usePatientLinkUrl(params.row.enrollmentMonth.enrollment.careEpisode.patient.id);
            return (
              <Badge
                badgeContent={t("patients:referenceHeader.testPatient")}
                invisible={!params.row.enrollmentMonth.enrollment.careEpisode.patient.isTest}
                color="success"
              >
                {/* We need to stop propagation otherwise it will do the row click even rather than the link */}
                <Link to={url} onClick={(event) => event.stopPropagation()}>
                  {params.row.enrollmentMonth.enrollment.careEpisode.patient.nameLastFirst}
                </Link>
              </Badge>
            );
          },
        },
        {
          field: "mrn",
          headerName: t("billing:prediction.columns.mrn"),
          sortable: true,
          flex: 1,
          valueGetter: (_value, row) => row.enrollmentMonth.enrollment.careEpisode.patient.mrn,
        },
        {
          field: "dob",
          headerName: t("billing:prediction.columns.dob"),
          sortable: true,
          minWidth: 120,
          valueGetter: (_value, row) =>
            row.enrollmentMonth.enrollment.careEpisode.patient.dob?.toLocaleDateString(),
          renderCell: (params) => {
            const dob = params.row.enrollmentMonth.enrollment.careEpisode.patient.dob;
            return dob ? t("common:date.tiny", { date: dob }) : "";
          },
        },
        {
          field: "careManager",
          headerName: t("billing:prediction.columns.careManager"),
          sortable: true,
          flex: 2,
          valueGetter: (_value, row) => row.enrollmentMonth.careManager?.orderedName ?? "",
        },
        {
          field: "pcp",
          headerName: t("billing:prediction.columns.pcp"),
          sortable: true,
          flex: 2,
          valueGetter: (_value, row) => row.enrollmentMonth.pcp?.orderedName ?? "",
        },
        // ====================================================================
        // Extra columns, usually hidden
        // ====================================================================
        {
          field: "organization",
          headerName: t("billing:prediction.columns.organization"),
          sortable: false,
          flex: 1,
          valueGetter: (_value, row) => row.enrollmentMonth.enrollment.careEpisode.organization.name,
        },
        {
          field: "monthOfEnrollment",
          headerName: t("billing:prediction.columns.monthOfEnrollment"),
          sortable: true,
          minWidth: 75,
          flex: 1,
          valueGetter: (_value, row) => row.enrollmentMonth.month,
        },
        {
          field: "currentMinutes",
          minWidth: 100,
          flex: 1,
          headerName: t("billing:prediction.columns.currentMinutes"),
          sortable: true,
          valueGetter: (_value, row) => row.enrollmentMonth.billableMinutes,
          renderCell: (params) => {
            return (
              <PatientBillableMinutesWithTargetBadgeElement
                billableMinutes={params.row.enrollmentMonth.billableMinutes}
                minutesTargetCeiling={params.row.enrollmentMonth.minutesTargetCeiling}
                minutesTargetFloor={params.row.enrollmentMonth.minutesTargetFloor}
                minutesTargetStatus={params.row.enrollmentMonth.minutesTargetStatus}
              />
            );
          },
        },
        // ====================================================================
        // Current billing details
        // ====================================================================
        {
          field: "winningResultEstimatedRate",
          headerName: t("billing:prediction.columns.winningResultEstimatedRate.full"),
          renderHeader: renderHeaderForGroup(
            t("billing:prediction.columns.winningResultEstimatedRate.grouped")
          ),
          sortable: true,
          minWidth: 100,
          flex: 1,
          valueGetter: (_value, row) =>
            row.winningResult?.expectedRate ? formatMoney(row.winningResult.expectedRate) : "",
          headerClassName: "mirah--highlight-current-billing",
          cellClassName: "mirah--highlight-current-billing",
        },
        {
          field: "winningResultRvus",
          headerName: t("billing:prediction.columns.winningResultRvus.full"),
          renderHeader: renderHeaderForGroup(t("billing:prediction.columns.winningResultRvus.grouped")),
          sortable: true,
          minWidth: 100,
          flex: 1,
          valueGetter: (_value, row) =>
            row.winningResult?.rvus ? formatNumber(row.winningResult.rvus, 2) : "",
          headerClassName: "mirah--highlight-current-billing",
          cellClassName: "mirah--highlight-current-billing",
        },
        {
          field: "winningResultValueUnits",
          headerName: t("billing:prediction.columns.winningResultValueUnits.full"),
          renderHeader: renderHeaderForGroup(t("billing:prediction.columns.winningResultValueUnits.grouped")),
          sortable: true,
          minWidth: 100,
          flex: 1,
          valueGetter: (_value, row) => row.winningResult?.valueUnits,
          headerClassName: "mirah--highlight-current-billing",
          cellClassName: "mirah--highlight-current-billing",
        },
        {
          field: "winningResultBillableMinutes",
          headerName: t("billing:prediction.columns.winningResultBillableMinutes.full"),
          renderHeader: renderHeaderForGroup(
            t("billing:prediction.columns.winningResultBillableMinutes.grouped")
          ),
          sortable: true,
          minWidth: 100,
          flex: 1,
          valueGetter: (_value, row) => formatMinutes(row.winningResult?.billableMinutes || 0, true),
          headerClassName: "mirah--highlight-current-billing",
          cellClassName: "mirah--highlight-current-billing",
        },
        {
          field: "winningResultBilledUnits",
          headerName: t("billing:prediction.columns.winningResultBilledUnits.full"),
          renderHeader: renderHeaderForGroup(
            t("billing:prediction.columns.winningResultBilledUnits.grouped")
          ),
          sortable: false,
          flex: 1,
          minWidth: 180,
          valueGetter: (_value, row) => billableUnitExplanationText(row.winningResult?.rule.ruleCodes),
          renderCell: (params) => {
            const rule = params.row.winningResult?.rule;
            if (rule) {
              return <BillableUnitExplanation rule={rule} />;
            } else {
              return "-";
            }
          },
          headerClassName: "mirah--highlight-current-billing",
          cellClassName: "mirah--highlight-current-billing",
        },
        // ====================================================================
        // Steps to next billing
        // ====================================================================
        {
          field: "nextResultSteps",
          sortable: true,
          headerName: t("billing:prediction.columns.nextResultSteps.full"),
          minWidth: 180,
          flex: 1,
          renderHeader: renderHeaderForGroup(t("billing:prediction.columns.nextResultSteps.grouped")),
          valueGetter: (_value, row) =>
            failReasonExplanationText(
              row.nextResult?.minutesShort ?? null,
              row.nextResult?.failReasonCodes ?? null,
              row.nextResult?.disqualifyReasonCodes ?? null,
              t
            ),
          renderCell: (params) => {
            const nextRule = params.row.nextResult;

            if (!nextRule) {
              return "";
            }

            return (
              <FailReasonsExplanation
                minutesShort={nextRule.minutesDiffVsCurrentWinner}
                failReasonCodes={nextRule.failReasonCodes}
                disqualifyReasonCodes={nextRule.disqualifyReasonCodes}
              />
            );
          },
        },
        {
          field: "ratePowerVsCurrentWinner",
          sortable: true,
          headerName: t("billing:prediction.columns.ratePowerVsCurrentWinner.full"),
          minWidth: 100,
          flex: 1,
          renderHeader: renderHeaderForGroup(
            t("billing:prediction.columns.ratePowerVsCurrentWinner.grouped")
          ),
          valueGetter: (_value, row) => row.nextResult?.ratePowerStrength ?? null,
          renderCell: (params) => {
            const nextRule = params.row.nextResult;

            if (!nextRule) {
              return "";
            }

            return <PowerBar strength={nextRule.ratePowerStrength} />;
          },
        },
        {
          field: "rvuPowerVsCurrentWinner",
          sortable: true,
          headerName: t("billing:prediction.columns.rvuPowerVsCurrentWinner.full"),
          minWidth: 100,
          flex: 1,
          renderHeader: renderHeaderForGroup(t("billing:prediction.columns.rvuPowerVsCurrentWinner.grouped")),
          valueGetter: (_value, row) => row.nextResult?.rvuPowerStrength ?? null,
          renderCell: (params) => {
            const nextRule = params.row.nextResult;

            if (!nextRule) {
              return "";
            }

            return <PowerBar strength={nextRule.rvuPowerStrength} />;
          },
        },
        {
          field: "valueUnitPowerVsCurrentWinner",
          sortable: true,
          headerName: t("billing:prediction.columns.valueUnitPowerVsCurrentWinner.full"),
          valueGetter: (_value, row) => row.nextResult?.valueUnitPowerStrength ?? null,
          minWidth: 100,
          flex: 1,
          renderHeader: renderHeaderForGroup(
            t("billing:prediction.columns.valueUnitPowerVsCurrentWinner.grouped")
          ),
          renderCell: (params) => {
            const nextRule = params.row.nextResult;

            if (!nextRule) {
              return "";
            }

            return <PowerBar strength={nextRule.valueUnitPowerStrength} />;
          },
        },
        {
          field: "billableMinutesPowerVsCurrentWinner",
          sortable: true,
          headerName: t("billing:prediction.columns.billableMinutesPowerVsCurrentWinner.full"),
          valueGetter: (_value, row) => row.nextResult?.valueUnitPowerStrength ?? null,
          minWidth: 100,
          flex: 1,
          renderHeader: renderHeaderForGroup(
            t("billing:prediction.columns.billableMinutesPowerVsCurrentWinner.grouped")
          ),
          renderCell: (params) => {
            const nextRule = params.row.nextResult;

            if (!nextRule) {
              return "";
            }

            return <PowerBar strength={nextRule.billableMinutesPowerStrength} />;
          },
        },
        // ====================================================================
        // Next billing details
        // ====================================================================
        {
          field: "nextResultRateDiffVsCurrentWinner",
          headerName: t("billing:prediction.columns.nextResultRateDiffVsCurrentWinner.full"),
          renderHeader: renderHeaderForGroup(
            t("billing:prediction.columns.nextResultRateDiffVsCurrentWinner.grouped")
          ),
          sortable: true,
          minWidth: 100,
          flex: 1,
          valueGetter: (_value, row) =>
            row.nextResult?.rateDiffVsCurrentWinner
              ? "+" + formatMoney(row.nextResult.rateDiffVsCurrentWinner)
              : "",
          headerClassName: "mirah--highlight-next-billing",
          cellClassName: "mirah--highlight-next-billing",
        },
        {
          field: "nextResultEstimatedRate",
          headerName: t("billing:prediction.columns.nextResultEstimatedRate.full"),
          renderHeader: renderHeaderForGroup(t("billing:prediction.columns.nextResultEstimatedRate.grouped")),
          sortable: true,
          minWidth: 100,
          flex: 1,
          valueGetter: (_value, row) =>
            row.nextResult?.expectedRate ? formatMoney(row.nextResult.expectedRate) : "",
          headerClassName: "mirah--highlight-next-billing",
          cellClassName: "mirah--highlight-next-billing",
        },
        {
          field: "nextResultRvuDiffVsCurrentWinner",
          headerName: t("billing:prediction.columns.nextResultRvuDiffVsCurrentWinner.full"),
          renderHeader: renderHeaderForGroup(
            t("billing:prediction.columns.nextResultRvuDiffVsCurrentWinner.grouped")
          ),
          sortable: true,
          minWidth: 100,
          flex: 1,
          valueGetter: (_value, row) =>
            row.nextResult?.rvuDiffVsCurrentWinner
              ? "+" + formatNumber(row.nextResult.rvuDiffVsCurrentWinner, 2)
              : "",
          headerClassName: "mirah--highlight-next-billing",
          cellClassName: "mirah--highlight-next-billing",
        },
        {
          field: "nextResultRvus",
          headerName: t("billing:prediction.columns.nextResultRvus.full"),
          renderHeader: renderHeaderForGroup(t("billing:prediction.columns.nextResultRvus.grouped")),
          sortable: true,
          minWidth: 100,
          flex: 1,
          valueGetter: (_value, row) => (row.nextResult?.rvus ? formatNumber(row.nextResult.rvus, 2) : ""),
          headerClassName: "mirah--highlight-next-billing",
          cellClassName: "mirah--highlight-next-billing",
        },
        {
          field: "nextResultValueUnitDiffVsCurrentWinner",
          headerName: t("billing:prediction.columns.nextResultValueUnitDiffVsCurrentWinner.full"),
          renderHeader: renderHeaderForGroup(
            t("billing:prediction.columns.nextResultValueUnitDiffVsCurrentWinner.grouped")
          ),
          sortable: true,
          minWidth: 100,
          flex: 1,
          valueGetter: (_value, row) =>
            row.nextResult?.valueUnitDiffVsCurrentWinner
              ? "+" + formatNumber(row.nextResult.valueUnitDiffVsCurrentWinner, 2)
              : "",
          headerClassName: "mirah--highlight-next-billing",
          cellClassName: "mirah--highlight-next-billing",
        },
        {
          field: "nextResultValueUnits",
          headerName: t("billing:prediction.columns.nextResultValueUnits.full"),
          renderHeader: renderHeaderForGroup(t("billing:prediction.columns.nextResultValueUnits.grouped")),
          sortable: true,
          minWidth: 100,
          flex: 1,
          valueGetter: (_value, row) => row.nextResult?.valueUnits,
          headerClassName: "mirah--highlight-next-billing",
          cellClassName: "mirah--highlight-next-billing",
        },
        {
          field: "nextResultBillableMinutesDiffVsCurrentWinner",
          headerName: t("billing:prediction.columns.nextResultBillableMinutesDiffVsCurrentWinner.full"),
          renderHeader: renderHeaderForGroup(
            t("billing:prediction.columns.nextResultBillableMinutesDiffVsCurrentWinner.grouped")
          ),
          sortable: true,
          minWidth: 100,
          flex: 1,
          valueGetter: (_value, row) =>
            row.nextResult?.billableMinutesDiffVsCurrentWinner
              ? "+" + formatMinutes(row.nextResult.billableMinutesDiffVsCurrentWinner)
              : "",
          headerClassName: "mirah--highlight-next-billing",
          cellClassName: "mirah--highlight-next-billing",
        },
        {
          field: "nextResultBillableMinutes",
          headerName: t("billing:prediction.columns.nextResultBillableMinutes.full"),
          renderHeader: renderHeaderForGroup(
            t("billing:prediction.columns.nextResultBillableMinutes.grouped")
          ),
          sortable: true,
          minWidth: 100,
          flex: 1,
          valueGetter: (_value, row) => formatMinutes(row.nextResult?.billableMinutes || 0, true),
          headerClassName: "mirah--highlight-next-billing",
          cellClassName: "mirah--highlight-next-billing",
        },
        {
          field: "nextResultBilledUnits",
          headerName: t("billing:prediction.columns.nextResultBilledUnits.full"),
          renderHeader: renderHeaderForGroup(t("billing:prediction.columns.nextResultBilledUnits.grouped")),
          sortable: false,
          flex: 1,
          minWidth: 180,
          valueGetter: (_value, row) => billableUnitExplanationText(row.nextResult?.rule.ruleCodes),
          renderCell: (params) => {
            const rule = params.row.nextResult?.rule;
            if (rule) {
              return <BillableUnitExplanation rule={rule} />;
            } else {
              return "";
            }
          },
          headerClassName: "mirah--highlight-next-billing",
          cellClassName: "mirah--highlight-next-billing",
        },
      ];

      return allCols.filter((column) => {
        const valence = VALUE_COLUMN_VALENCE[column.field];

        if (valence) {
          return allowedValueTypes.includes(valence);
        } else {
          return true;
        }
      });
    }, allowedValueTypes);

  const columnGrouping: GridColumnGroupingModel = React.useMemo(() => {
    return [
      {
        groupId: "winningResult",
        children: [
          { field: "winningResultEstimatedRate" },
          { field: "winningResultRvus" },
          { field: "winningResultValueUnits" },
          { field: "winningResultBillableMinutes" },
          { field: "winningResultBilledUnits" },
        ],
        headerName: t("billing:prediction.columnGroups.winningResult"),
        headerClassName: "mirah--centered-header mirah--highlight-current-billing",
      },
      {
        groupId: "nextResult",
        children: [
          { field: "nextResultRateDiffVsCurrentWinner" },
          { field: "nextResultEstimatedRate" },
          { field: "nextResultRvuDiffVsCurrentWinner" },
          { field: "nextResultRvus" },
          { field: "nextResultValueUnitDiffVsCurrentWinner" },
          { field: "nextResultValueUnits" },
          { field: "nextResultBillableMinutesDiffVsCurrentWinner" },
          { field: "nextResultBillableMinutes" },
          { field: "nextResultBilledUnits" },
        ],
        headerName: t("billing:prediction.columnGroups.nextResult"),
        headerClassName: "mirah--centered-header mirah--highlight-next-billing",
      },
    ];
  }, []);

  return (
    <BillingTableContainer>
      <SortablePagableCollectionDataGrid
        queryHook={useBillingPredictionQuery}
        storageKey={`${COCM_BILLING_PREDICTION_STORAGE_KEY}-${props.viewContext}`}
        queryVariables={queryVars}
        unwrapData={(response) => response?.billingEnrollmentMonthBillingRuleResultWinners || null}
        colNameToSortParam={(field) => {
          switch (field) {
            case "patientName":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.PATIENT_NAME;
            case "mrn":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.PATIENT_MRN;
            case "dob":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.PATIENT_DOB;
            case "careManager":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.CARE_MANAGER_NAME;
            case "pcp":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.PCP_NAME;
            case "currentMinutes":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.CURRENT_MINUTES;
            case "monthOfEnrollment":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.MONTH_OF_TREATMENT;
            case "winningResultEstimatedRate":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.CURRENT_WINNER_EXPECTED_RATE_CENTS;
            case "winningResultRvus":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.CURRENT_WINNER_RVUS;
            case "winningResultValueUnits":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.CURRENT_WINNER_VALUE_UNITS;
            case "winningResultBillableMinutes":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.CURRENT_WINNER_BILLABLE_MINUTES;
            case "nextResultEstimatedRate":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.NEXT_WINNER_EXPECTED_RATE_CENTS;
            case "nextResultRvus":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.NEXT_WINNER_RVUS;
            case "nextResultValueUnits":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.NEXT_WINNER_VALUE_UNITS;
            case "nextResultSteps":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.MINUTES_SHORT;
            case "ratePowerVsCurrentWinner":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.RATE_POWER_VS_CURRENT_WINNER;
            case "rvuPowerVsCurrentWinner":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.RVU_POWER_VS_CURRENT_WINNER;
            case "valueUnitPowerVsCurrentWinner":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.VALUE_UNIT_POWER_VS_CURRENT_WINNER;
            case "nextResultRateDiffVsCurrentWinner":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.NEXT_WINNER_EXPECTED_RATE_CENTS;
            case "nextResultRvuDiffVsCurrentWinner":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.NEXT_WINNER_RVU_DIFF_VS_CURRENT_WINNER;
            case "nextResultValueUnitDiffVsCurrentWinner":
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.NEXT_WINNER_RVU_DIFF_VS_CURRENT_WINNER;

            default:
              return EnrollmentMonthBillingRuleResultWinnerSortParameter.ID;
          }
        }}
        columns={columns}
        defaultPageSize={10}
        getRowId={(row) => row.id.toString()}
        columnGroupingModel={columnGrouping}
        apiRef={apiRef}
        showExportToolbar
        initialState={{
          columns: {
            columnVisibilityModel,
          },
        }}
        onRowClick={
          onRowClick
            ? (row) => {
                onRowClick(row.row.id);
              }
            : undefined
        }
        autoHeight
        defaultSortParams={{
          sortDirection: SortDirection.DESC,
          sortBy: EnrollmentMonthBillingRuleResultWinnerSortParameter.MINUTES_SHORT,
        }}
      />
    </BillingTableContainer>
  );
}

export default function BillingPrediction() {
  const { t } = useTranslation(["billing"]);
  const filters = useBillingPredictionFilters();
  const [activeWinnerId, setActiveWinnerId] =
    useQueryStringIdParameter<"EnrollmentMonthBillingRuleResultWinner">("winnerId", true);

  const details = activeWinnerId ? (
    <BillingWinnerDetailsModal winnerId={activeWinnerId} onClose={() => setActiveWinnerId(null)} />
  ) : null;

  const isSuperuser = useProviderUserHasAnyRole(["Collaborative Care Superuser", "superuser"]);

  const cmAllowedValueTypes = useConfiguration("cocmCareManagerVisibleBillingValueTypes");

  const allowedValueTypes = isSuperuser ? ALL_VALUE_TYPES : cmAllowedValueTypes;

  const omittedValueTypes = React.useMemo(() => {
    return ALL_VALUE_TYPES.filter((x) => !allowedValueTypes.includes(x));
  }, [isSuperuser]);

  const defaultValueType =
    ORDERED_VALUE_TYPES.find((item) => cmAllowedValueTypes.includes(item)) ??
    CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS;

  return (
    <Page browserTitle={t("billing:prediction.pageTitle")} supportsPanels>
      <BillingPredictionFilters
        filters={filters}
        omittedValueTypes={omittedValueTypes}
        defaultValueType={defaultValueType}
      />
      <BillingPredictionTable
        filters={filters}
        allowedValueTypes={allowedValueTypes}
        onRowClick={setActiveWinnerId}
        viewContext="fullPredictionPage"
      />
      {details}
    </Page>
  );
}

// DataGrid doesn't let us supply our own components for these things, just add class names to them, so we have to
// define what the class names do in a container here.
const BillingTableContainer = styled(Paper)(({ theme }) => ({
  "& .mirah--centered-header .MuiDataGrid-columnHeaderTitleContainer": {
    justifyContent: "center",
  },
  "& .mirah--highlight-current-billing.MuiDataGrid-columnHeader": {
    backgroundColor: theme.palette.billing.currentBillable,
  },
  "& .mirah--highlight-next-billing.MuiDataGrid-columnHeader": {
    backgroundColor: theme.palette.billing.nextBillable,
  },
  "& .mirah--highlight-current-billing.MuiDataGrid-cell": {
    backgroundColor: theme.palette.billing.currentBillable,
  },
  "& .mirah--highlight-next-billing.MuiDataGrid-cell": {
    backgroundColor: theme.palette.billing.nextBillable,
  },
}));
