import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  CocmBillingAlgorithmRule,
  CocmBillingAlgorithmRuleCheckValue,
  CocmEstimatedBillingStatsSummary,
  CocmEstimatedBillingStatsSummaryStat,
  EntityTreeNodeParams,
  ImplementationTargetReportGraphDataUnit,
  IntegerRange,
  MonthParams,
  useBillingPredictionSummaryStatsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import React, { useContext } from "react";
import {
  Axis,
  Grid as XYGrid,
  Tooltip,
  XYChart,
  BarSeries,
  BarGroup,
  DataContext,
  DataProvider,
} from "@visx/xychart";
import { useTranslation } from "react-i18next";
import { DEFAULT_CHART_THEME } from "Shared/GraphTheme";
import { BillingRuleNameDetails, billingRuleName, shortBillingRuleName } from "./BillingRuleHelpers";
import { LegendOrdinal } from "@visx/legend";
import { assertNonNull } from "Lib/Utils";
import { formatValueFromUnitClass } from "Implementation/ImplementationTargetElements";
import { TFunction } from "i18next";

type SummaryStatItem = Pick<
  CocmEstimatedBillingStatsSummaryStat,
  "avgEnrollmentMonths" | "avgRateCents" | "avgRvus" | "avgValueUnits" | "avgBillableMinutes"
> & {
  rule: Pick<CocmBillingAlgorithmRule, "id" | "priority">;
};

type SeriesTypes = "current" | "historical" | "previous";

const seriesFormatter = (key: SeriesTypes, t: TFunction<["billing"]>) => {
  switch (key) {
    case "historical":
      return t("billing:stats.series.historical");
    case "previous":
      return t("billing:stats.series.previous");
    case "current":
      return t("billing:stats.series.current");
  }
};

type SummaryStats = Pick<
  CocmEstimatedBillingStatsSummary,
  "maxRateCents" | "maxRvus" | "maxValueUnits" | "maxBillableMinutes"
> & {
  currentMonthData: ReadonlyArray<SummaryStatItem>;
  previousMonthData: ReadonlyArray<SummaryStatItem>;
  historicalAverageData: ReadonlyArray<SummaryStatItem>;
};

function BillingSummaryStatsInner(props: {
  stats: SummaryStats;
  valueType: CocmBillingAlgorithmRuleCheckValue;
  rules: ReadonlyArray<BillingRuleNameDetails>;
}) {
  const { t } = useTranslation(["billing"]);
  const { stats, valueType, rules } = props;
  const yAccessor = (datum: SummaryStatItem) => {
    switch (valueType) {
      case CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE:
        return datum.avgRateCents;
      case CocmBillingAlgorithmRuleCheckValue.RVUS:
        return datum.avgRvus;
      case CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS:
        return datum.avgValueUnits;
      case CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES:
        return datum.avgBillableMinutes;
    }
  };

  const yFormatter = (datum: SummaryStatItem) => {
    switch (valueType) {
      case CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE:
        return formatValueFromUnitClass(
          datum.avgRateCents,
          ImplementationTargetReportGraphDataUnit.MONEY_CENTS
        );
      case CocmBillingAlgorithmRuleCheckValue.RVUS:
        return formatValueFromUnitClass(datum.avgRvus, ImplementationTargetReportGraphDataUnit.FLOAT);
      case CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS:
        return formatValueFromUnitClass(datum.avgValueUnits, ImplementationTargetReportGraphDataUnit.FLOAT);
      case CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES:
        return formatValueFromUnitClass(
          datum.avgBillableMinutes,
          ImplementationTargetReportGraphDataUnit.MINUTES
        );
    }
  };

  const xAccessor = (datum: SummaryStatItem) => {
    return datum.rule.id;
  };

  let maxValue = (() => {
    switch (valueType) {
      case CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE:
        return stats.maxRateCents;
      case CocmBillingAlgorithmRuleCheckValue.RVUS:
        return stats.maxRvus;
      case CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS:
        return stats.maxValueUnits;
      case CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES:
        return stats.maxBillableMinutes;
    }
  })();

  // maxValue can be null if there are no values to plot, in which case it doesn't really matter what we set the y
  // scale to, so 0 is as good as anything else here.
  maxValue = maxValue === null ? 0 : maxValue;

  const valueClass = (() => {
    switch (valueType) {
      case CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE:
        return ImplementationTargetReportGraphDataUnit.MONEY_CENTS;
      case CocmBillingAlgorithmRuleCheckValue.RVUS:
        return ImplementationTargetReportGraphDataUnit.FLOAT;
      case CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS:
        return ImplementationTargetReportGraphDataUnit.FLOAT;
      case CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES:
        return ImplementationTargetReportGraphDataUnit.MINUTES;
    }
  })();

  return (
    <DataProvider
      xScale={{ type: "band", domain: props.rules.map((d) => d.id), paddingInner: 0.6, paddingOuter: 0.1 }}
      yScale={{ type: "linear", domain: [0, maxValue] }}
      theme={DEFAULT_CHART_THEME}
    >
      <XYChart theme={DEFAULT_CHART_THEME} height={300} margin={{ top: 20, right: 20, bottom: 40, left: 70 }}>
        <XYGrid numTicks={6} strokeDasharray="3 3" />
        <Axis
          orientation="left"
          numTicks={6}
          hideAxisLine
          tickFormat={(t) => {
            return formatValueFromUnitClass(t, valueClass);
          }}
        />
        <Axis
          orientation="bottom"
          hideAxisLine
          rangePadding={50}
          tickFormat={(value) => {
            const rule = rules.find((r) => r.id === value);

            if (rule) {
              return shortBillingRuleName(rule);
            } else {
              return "?";
            }
          }}
        />
        <BarGroup>
          <BarSeries
            data={[...stats.historicalAverageData]}
            dataKey="historical"
            yAccessor={yAccessor}
            xAccessor={xAccessor}
          />
          <BarSeries
            data={[...stats.previousMonthData]}
            dataKey="previous"
            yAccessor={yAccessor}
            xAccessor={xAccessor}
          />
          <BarSeries
            data={[...stats.currentMonthData]}
            dataKey="current"
            yAccessor={yAccessor}
            xAccessor={xAccessor}
          />
        </BarGroup>
        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          showSeriesGlyphs
          renderTooltip={({ tooltipData, colorScale }) => {
            if (!tooltipData || !tooltipData.nearestDatum || !colorScale) {
              return <></>;
            }

            const closestItem = tooltipData.nearestDatum.datum as SummaryStatItem;
            const rule = props.rules.find((r) => r.id === closestItem.rule.id);

            if (!rule) {
              return <></>;
            }

            const entries = Object.entries(tooltipData.datumByKey).map(([key, datum]) => {
              const item = datum.datum as SummaryStatItem;
              return (
                <div key={key} style={{ color: colorScale(key) }}>
                  {seriesFormatter(key as SeriesTypes, t)}:{yFormatter(item)}
                </div>
              );
            });

            return (
              <div>
                <h2>{billingRuleName(rule)}</h2>
                {entries}
              </div>
            );
          }}
        />
      </XYChart>
      <ChartLegend />
    </DataProvider>
  );
}

// See this codepen on why moving this into a data context makes sense
// https://codesandbox.io/p/sandbox/xychart-legend-demo-mgqr7?file=%2FExample.tsx%3A96%2C26
function ChartLegend() {
  const { colorScale } = useContext(DataContext);
  const { t } = useTranslation(["billing"]);

  return (
    <LegendOrdinal
      direction="row"
      itemMargin="8px 8px 8px 0"
      scale={assertNonNull(colorScale)} // I do not understand why this thinks it's not null, but the code works in all circumstances I have found.
      labelFormat={(label) => seriesFormatter(label as SeriesTypes, t)}
    />
  );
}

export function BillingSummaryStats(props: {
  month: MonthParams;
  entityTreeNode: EntityTreeNodeParams;
  valueType: CocmBillingAlgorithmRuleCheckValue;
  rules: ReadonlyArray<BillingRuleNameDetails>;
  monthOfEnrollment: ReadonlyArray<IntegerRange>;
}) {
  const { t } = useTranslation(["billing"]);

  const { remoteData } = apolloQueryHookWrapper(
    useBillingPredictionSummaryStatsQuery({
      variables: {
        entityTreeNode: props.entityTreeNode,
        month: props.month,
        monthOfEnrollmentRange: props.monthOfEnrollment,
        rules: null,
      },
    })
  );

  const content = remoteData.caseOf({
    Success: (result) => {
      const data = result.billingCocmEstimatedBillingStatsSummary;

      if (!data) {
        return <Typography>{t("billing:stats.notAvailable")}</Typography>;
      }
      return <BillingSummaryStatsInner rules={props.rules} stats={data} valueType={props.valueType} />;
    },
    Failure: (error) => <ErrorMessage message={error.message} />,
    Loading: () => <Spinner />,
    NotAsked: () => <Spinner />,
  });

  return (
    <Card>
      <CardHeader title={t("billing:stats.title")} subheader={t("billing:stats.subheader")} />
      <CardContent>{content}</CardContent>
    </Card>
  );
}
