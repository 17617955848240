const collaborativeCare = {
  appBar: {
    saveProgress: "Save Progress",
    completeTask: "Complete Task",
    noPatient: "No Patient",
  },
  careManagerDashboard: {
    title: "Mirah Care Manager Dashboard",
    tasksHeader: "My Tasks",
    tasksError: "Failed to load task list",
    inboxHeader: "Measurement Inbox",
    noMeasurements: "There are no measurements yet",
    taskList: {
      noTasksMessage: "No tasks match your filters",
      createTask: "Create Task",
      invisibleNewTask:
        "Task created successfully, but is not visible. Either return to the first page of tasks or clear the status filter.",
      sendToTop: "Send to Top",
      internalUserError:
        "Internal Users do not have tasks list. Feature to preview customer's tasks lists will be implemented soon",
      hiddenPanelTasks_one: "One task is hidden because of your panel selection",
      hiddenPanelTasks_other: "{{ count }} tasks are hidden because of your panel selection",
    },
    interventionMinutesTotal:
      "The number of possibly billable minutes recorded this month for all your patients",
    testPatientViewability: "Viewing Test Patients?",
  },
  dailyAppointments: {
    genericQueryError: "Failed to load Daily Appointments",
    pcpLabel: "PCP:",
  },
  findings: {
    title: "Inbox",
    noFindings: "No notifications need your attention",
    hiddenPanelFindings_one: "One notification is hidden because of your panel selection",
    hiddenPanelFindings_other: "{{ count }} notifications are hidden because of your panel selection",
    genericQueryError: "Failed to load Findings",
    caregiverUpper: "Caregiver",
    caregiverLower: "caregiver",
    findings: {
      assessmentNeedsReview: {
        title: "Assessment Needs Review",
        body: "has updated or completed an assessment which needs a review.",
        startTaskNow: "View Report",
      },
      timeElapsedSinceInvitation: {
        title: "Open Assessment Incomplete for 7 Days",
        body: "has not completed their assessment(s) in over 7 days. This includes: ",
        viewMeasures: "View Measures",
        reminderTask: {
          title: "Ensure {{ caregiverName }} Assessment Completion",
          body: "has had an open assessment for over 7 days. This includes:",
        },
      },
      monthlyAssessmentIncomplete: {
        title: "Monthly Assessment Incomplete",
        body: "has not completed a validated assessment this month, which may impact the ability to bill for care this month.",
        viewMeasures: "View Measures",
        reminderTask: {
          title: "Ensure Monthly Assessment Completion",
          body: "has not completed a validated assessment for this month.",
        },
      },
      endOfMonthPush: {
        title: "Closing Out the Month",
        table: {
          patient: "Patient",
          nextStep: "Next Step",
          codes: "Codes",
        },
        actions: {
          moreBillingInsights: "More Billing Insights",
        },
      },
    },
    administerNowTask: {
      title: "Administer Assessments",
      titleCaregiver: "Administer Caregiver Assessments",
      body: "Administering assessments to ",
    },
    actions: {
      notifyAndSnooze: "Send Notification",
      startTaskNow: "Start",
      createTaskLater: "Create Task",
      dismiss: "Dismiss",
    },
  },
  patientList: {
    title: "Find Patients",
    search: {
      clearFilter: "Clear Filter",
      searchClientsLabel: "Search Clients",
      careManagerLabel: "Care Manager",
      primaryCareProviderLabel: "Primary Care Provider",
      enrollmentStatus: "Enrollment Status",
      footer_zero: "Total patients matching your filters: {{ fullSearchResultCount }}",
      footer_one:
        "Patients in selected panels matching your filters: {{ searchWithPanelsCount }}. (Total patients: {{ fullSearchResultCount }})",
      footer_other:
        "Patients in selected panels matching your filters: {{ searchWithPanelsCount }}. (Total patients: {{ fullSearchResultCount }})",
    },
    fields: {
      status: "Status",
      minutes: "Minutes",
      patientName: "Name",
      gender: "Gender",
      dob: "DOB",
      enrollment: "Enrollment",
      pcp: "PCP",
      cm: "CM",
      mrn: "MRN",
    },
  },
  fields: {
    status: {
      label: "Status",
    },
    title: {
      label: "Title",
      missing: "Please enter a title",
    },
    body: {
      label: "Body",
    },
    patient: {
      label: "Patient",
    },
    dueAt: {
      label: "Due by",
      format: "MM/dd/yyyy hh:mm",
      missing: "Please enter a due date",
    },
    assignedTo: {
      label: "Assign to",
      labelPastTense: "Assigned to",
      missing: "Please enter an assigned person",
    },
    manualStartTime: {
      label: "Start Time (required)",
      missing: "Please enter the start time",
    },
    duration: {
      label: "Duration in Minutes",
    },
    manualEndTime: {
      label: "End Time",
    },
    pcp: {
      label: "Primary Care Provider",
    },
    minutes: {
      label: "Minutes",
    },
    hours: {
      label: "Hours",
    },
  },
  filters: {
    task: {
      status: "Task Status Filter",
    },
    billableMinutes: "Has Billable Minutes",
  },
  monthlyBillingReport: {
    title: "Mirah Monthly Billing Report",
    header: "Monthly Billing Report",
    genericQueryError: "Failed to load Monthly Billing Report",
    loading: "Crunching the latest numbers, just for you",
    monthlyBillableMinutes: "Monthly billable minutes:",
    notEligible: "Not Eligible",
    fields: {
      yearAndMonth: "Year and Month",
      patientName: "Patient Legal Name",
      mrn: "MRN",
      dob: "DOB",
      billableMinutes: "Billable Minutes",
      providerName: "PCP",
      careManagerName: "CM",
      providerNpi: "PCP NPI",
      enrolledAt: "Enrolled On",
      billingMonth: "Billing Month",
      monthsEnrolled: "Month",
      assessmentCompleted: "Assessment Completed",
      pdf: "PDF",
      organizationName: "Organization",
      billableUnits: "Codes",
    },
  },
  taskTemplates: {
    title: "Mirah Care Manager Quick Tasks",
    header: "Quick Tasks",
    createHeader: "New Quick Task",
    editHeader: "Edit Quick Task",
    deleteHeader: "Delete Quick Task",
    genericFormError: "Failed to save quick task",
    genericQueryError: "Failed to load quick tasks",
    fields: {
      title: "Title",
      titleMissing: "Please enter a title",
      description: "Description",
    },
  },
  tasks: {
    clickToAddDescription: "_Click to add a description_",
    patientSubhead: "For {{ patient }}",
    dueAtSubhead: "Due by $t(common:date.shortTime)",
    dueAtSubheadShort: "$t(common:date.shortTime)",
    assignedSubhead: "Assigned to {{ provider }}",
    createHeader: "New Task",
    editHeader: "Edit Task",
    noTasksMessage: "No tasks for this patient",
    genericFormError: "Failed to save task",
    genericQueryError: "Failed to load tasks",
    genericNoteFormError: "Failed to save note",
    noPrefilledTask: "Custom",
    noteAuthorHeader: "$t(common:date.shortTime) | {{ author }}",
    timeEntryLogMinutes: "logged {{ hours }} hours at",
    newNote: "New Note",
    noteWillBeDeleted: "This note will be deleted",
    beforeConsentWarning:
      "This task has a start time before the patient consent date of $t(common:date.tinyWithTime), and won't be included in billable minutes totals.",
    quickTrack: "Quick Track",
    alreadyComplete: "Task is complete",
    logsTitle: "History",
    noLogs: "This task has no notes or logged time",
    patientListFooter_zero: "Total tasks matching your filters: {{ fullSearchResultCount }}",
    patientListFooter_one:
      "Tasks in selected panels matching your filters: {{ searchWithPanelsCount }}. (Total tasks: {{ fullSearchResultCount }})",
    patientListFooter_other:
      "Tasks in selected panels matching your filters: {{ searchWithPanelsCount }}. (Total tasks: {{ fullSearchResultCount }})",
    minutesTotal: "Total",
    actions: {
      addNote: "Add Note",
      logTime: "Log Time",
      complete: "Complete",
      begin: "Begin Task",
      save: "Save Progress",
      discard: "Discard Time",
      saveAndBegin: "Save & Begin",
      alreadyComplete: "Done",
      completeForm: "Mark Task as {{ status }} and Add Note",
      tooltips: {
        disabledTaskAlreadyStarted: "This task is already being worked on.",
        disabledStartBlockedByTracking:
          "Another task is being worked on currently, to start this task, save or complete the first one.",
        disabledCompleteBlockedByTracking:
          "Another task is being worked on currently, to complete this task, save or complete the first one.",
        disabledLogTimeBlockedByTracking:
          "Another task is being worked on currently, to log time for this task, save or complete the first one.",
        disabledAlreadyComplete: "This task is complete.",
      },
    },
    errors: {
      failedComplete: "Failed to complete task",
      failedStartTracking: "Failed to begin task",
      failedStopTracking: "Failed to save progress",
    },
    details: {
      notesTab: {
        label: "Previous Notes",
        none: "There are no notes",
      },
      forTime: "for time at",
      timeEntryTab: {
        label: "Logged Time",
        none: "There is no logged time",
      },
    },
    timeEntryLog: {
      logTimeHeader: "Log Time for Task",
      modifyTimeHeader: "Adjust Time and Add Notes",
      modifyTimeSave: "Update",
      deleteTimeEntryButtonLabel: "Discard Time",
      deleteTimeEntryHeader: "Delete Time Entry",
      TaskCompleted: "Task is Done",
      MarkTaskCompleteHelp: "Check the box to mark Task as {{ status }} on save",
      TaskWillBeCompletedHelp: "Task will be marked as {{ status }} on save",
      dateFormat: "MM/dd/yyyy hh:mm aa",
      needsReview:
        "This time was originally logged as {{ unreviewedMinutes }} minutes. Your practice's policy automatically shortened this to {{ durationMinutes }} minutes in case a forgotten timer was left running accidentally. Edit this time entry to have the correct duration to remove this warning.",
      reviewed:
        "This time was previously shortened because of your practice's policy. It was reviewed and updated to the correct duration at $(common:date.long).",
      noteText: {
        label: "Notes",
      },
    },
    noPatient: "No Patient",
    automaticTasks: {
      viewPatientDetails: {
        title: "Chart Review",
        body: "",
      },
      viewFeedbackReport: {
        title: "Assessment Results Review",
        body: "",
      },
    },
  },
  taskCreationLog: {
    prompt: "Who created this task?",
    explanations: {
      manualNoAssignerOrRequester: "This task was created manually. It was assigned to {{ assignedTo }}.",
      manualAssignedBy:
        "This task was created manually. It was assigned to {{ assignedTo }} by {{ assignedBy }}.",
      manualRequestedBy:
        "This task was created manually. It was requested by {{ requestedBy }} and assigned to {{ assignedTo }}.",
      manualAssignedByAndRequestedBy:
        "This task was created manually. It was requested by {{ requestedBy }} and assigned tp {{ assignedTo }} by {{ assignedBy }}.",
      sessionDriven:
        "This task was created automatically as a follow up to {{ patient }}'s appointment or survey on $t(common:date.medium).",
    },
  },
  patientDetails: {
    title: "Mirah",
    statusError: "Failed to Load",
    surveyHistory: {
      notEnrolled: "This patient is not enrolled in collaborative care",
      reportLink: "Full report",
      noAdministrations: "This patient has not filled out any surveys",
      previousEnrollmentNoAdministrations: "This patient did not fill out any surveys",
      noUpcomingSessions: "No scheduled measurements",
      upcomingSessions: "Scheduled Measurements",
      cantLoadCareEpisode: "Unable to load care episode",
      measurementHistory: "Measurement History",
      noBundles: "This patient has no assigned bundles",
      activeBundles: "Active Measurement Bundles",
      bundleStartDate: "since $t(common:date.medium)",
      bundleMeasuresList: "Monthly measures: {{ measuresList }}",
      removeBundle: "Remove {{ title }} bundle",
      removeBundleWarning:
        "Removing this bundle will mean that the following measures will not be administered to the patient each month: {{ measureList }}. Are you sure you want to remove this bundle?",
      duplicateBundles: "This bundle is already assigned",
      bundle: "Bundle",
      bundles: "Measurement Bundles",
    },
    activeEncounter: {
      title: "Documentation Encounter",
      fetching: "We are fetching details from the encounter from your EMR.",
      fetchError:
        "There was an error loading details of this encounter. Please reload from the EMR if you wish to associate this enrollment month with this encounter",
      setEncounter:
        "There is currently no encounter for this month. Would you like to set the following encounter as the encounter to which to send documentation?",
      overwriteEncounter:
        "There is already a documentation encounter set for this month. Would you like to update it to point to this encounter instead?",
      alreadySet: "This encounter is already the documentation encounter set for this month",
      justSet: "This encounter has been set for the documentation encounter for this month",
      setEncounterButton: "Set as documentation encounter",
      setEncounterButtonNoAction: "Already documentation encounter",
      explanation:
        "The documentation encounter is the encounter in the EMR to which notes and time logs will be automatically sent.",
    },
    enrollmentHistory: {
      enrollmentName:
        "Enrollment {{ enrolledAt; date(format:MMM dd, yyyy) }} - {{ dischargedAt; date(format:MMM dd, yyyy) }}",
      enrollmentHistory: "Enrollment History",
      enrollmentDetails: "Enrollment Details",
      noEnrollment: "Enrollment not found",
      noPreviousEnrollments: "This patient has no previous enrollments.",
      panelName: "Panel Name",
      primaryCareProvider: "Primary Care Provider",
      careManager: "Care Manager",
      enrollmentPeriod: "Enrollment Period",
      consentDate: "Consent Date",
      measurementPlans: "Measurement Plans",
      dischargeDate: "Discharge Date",
      dischargeReason: "Discharge Reason",
      details: "Details",
      viewingText: "You are viewing a previous enrollment",
      for: "for",
      reactivateEnrollment: "Reactivate Enrollment",
      reactivateEnrollmentText:
        "Incomplete assessment invitations which have not expired will be re-opened. Tasks will not be automatically re-opened.",
      reactivate: "Reactivate",
      reactivateDisabled:
        "Enrollments must have been discharged either the current month or last month in order to be eligible for reactivation.",
    },
    enrollmentMonths: {
      title: "Monthly Summaries",
      beginningOfMonth: "Month",
      noEnrollment: "There is no active enrollment for which to show monthly summaries.",
      minutes: "Minutes",
      pdf: "PDF",
      month: "Month #",
      billing: "Expected Billing",
      validatedMeasureCompleted: "Assessment",
    },
    immediateSurveyForm: {
      titleWithPatientName: "Send Measurement ({{patient}})",
      title: "Send Measurement",
      noScales: "Please select one or more measures",
      genericFormError: "Failed to send survey",
      sendAction: "Submit",
      administerInterviewHelp:
        "Clicking a button below will automatically create a task and begin tracking time for it.",
      administerInterviewProviderWarning: "You must be a provider user in order to administer interviews.",
      taskTitle: "Administer {{scaleNames}} for {{recipients}}",
      taskBody:
        "You sent the following scales to {{recipients}}: {{scaleNames}}. [Click here](/provider/assessment/invitation/{{invitationId}}/edit) to view / edit responses.",
    },
    billableMinutesBadge: {
      title: "Billable Minutes",
      explanationNoTarget: "There have been {{billableMinutes}} minutes accrued for this month",
      explanation:
        "There have been {{billableMinutes}} minutes accrued for this month. Target is between {{minutesTargetFloor}} and {{minutesTargetCeiling}}",
    },
    severityBadge: {
      title: "{{ name }} Status",
      explanations: {
        alert: {
          ALLIANCE: {
            ALERT: "{{ name }} endorsed poor therapeutic alliance on their most recent {{ scale }}.",
            NO_ALERT:
              "{{ name }} did not endorse poor therapeutic alliance on their most recent {{ scale }}.",
          },
          MEDICATIONS: {
            ALERT: "{{ name }} endorsed improper medication use on their most recent {{ scale }}.",
            NO_ALERT: "{{ name }} did not endorse improper medication use on their most recent {{ scale }}.",
          },
          SUBSTANCE_USE: {
            ALERT: "{{ name }} endorsed an alcohol or drug problem on their most recent {{ scale }}.",
            NO_ALERT:
              "{{ name }} did not endorse an alcohol or drug problme on their most recent {{ scale }}.",
          },
          SUICIDALITY: {
            ALERT: "{{ name }} endorsed self-harm on their most recent {{ scale }}.",
            NO_ALERT: "{{ name }} did not endorse self-harm on their most recent {{ scale }}.",
          },
          notMeasured: "{{ name }} has not taken any treatment risk measures.",
        },
        target: {
          value: "{{ name }} scored {{ threshold }} on their target scale {{ scale }}.",
          noValue: "{{ name }} has not taken any target measures.",
        },
        generalFunctioning: {
          value: "{{ name }} scored {{ threshold }} on their general functioning scale {{ scale }}.",
          noValue: "{{ name }} has not taken any general functioning measures.",
        },
      },
    },
    cards: {
      measurementHistory: "Measurement History",
      patientInfo: "Patient Info",
      relatedPeople: "Related People",
      tasks: "Tasks",
      timeline: "Timeline",
    },
    actions: {
      tasks: "Tasks",
      enrollment: "Enrollment Lifecycle",
      consult: "Consult",
    },
    tasks: {
      actions: {
        filters: "Filters",
        copyMonthlyTasksSummary: "Copy Monthly Tasks Summary",
        copyMonthlyTasksSummaryCopied: "Copied!",
        copyMonthlyTasksSummaryHelp:
          "Copies all tasks with more than 0 minutes for the currently selected month onto the clipboard. This is suitable for pasting into an EHR.",
      },
      failure: "There was an error loading tasks for this patient",
      filters: {
        yearAndMonth: "Year and Month",
        yearAndMonthHelp:
          "This updates the Minutes column to only calculate minutes that actually occured in a given calendar month. It does not change what tasks are actually displayed.",
        status: "Status",
      },
      placeholders: {
        descriptionStart: "This is an unspecified task. You can:",
        descriptionMerge: "merge with an existing task",
        descriptionTemplate: "apply a task template",
        descriptionDetails: "add custom details",
        descriptionEnd: "...or do this at any time later.",
        merge: {
          description:
            "By merging with another task, all existing time entries and notes from this task (including any entered on this page) will be moved to the selected task. You may only merge with incomplete, full tasks.",
          merge: "Merge",
          back: "Back",
          noTargets: "There are no tasks on this patient to merge into.",
          loadTasksError: "There was an error loading tasks to merge into",
        },
      },
    },
    timeline: {
      failure: "There was an error loading the timeline for this patient",
    },
  },
  enrollment: {
    title: "Patient Enrollment ({{patient}})",
    intakeTitle: "Send or Administer Intake Scales",
    noRecipient: "No recipient. Click here to add a new related person.",
    noRecipientWithRelationship: "No recipient. Click here to add a new {{ relationship }}.",
    consentPrompt:
      "I have confirmed all documentation and consent for enrollment into collaborative care has been collected for {{ name }}",
    pcp: "PCP",
    enrollmentDate: "Enrollment Date",
    enrollmentDateHelp:
      "Enrollment date is used to calculate target minutes per month, elapsed treatment time, and other clinical aspects.",
    consentDate: "Consent Date",
    consentDateHelp: "Consent date is used to track the earliest date which minutes can be billed",
    genericErrorMessage: "Failed to enroll patient",
    addBundlesPrompt: "Add initial bundles to {{ patient }}'s enrollment",
    addBundlesPromptWithAge: "Add initial bundles to {{ patient }}'s (age {{ age }}) enrollment",
    bundlesDisplayHelpNoSelection: "Selected bundles and their measures will appear here.",
    bundlesDisplayHelpWithSelection:
      "Duplicate measures across bundles will only be sent to each recipient once.",
    addTasksPrompt: "Add any tasks needed for additional followups for {{ patient }} below",
    anotherTask: "Add another task",
    activeEnrollments: "Active Enrollments",
    enrollmentSuccessful:
      "{{ patient }} has been successfully enrolled, below you can view the tasks added to the patient",
    administerManually: "Start Interview",
    sendNotification: "Send Email/Text",
    unenrolled: "Unenrolled",
    notMeasured: "No Measure History",
    manualMeasuresPrompt: "You selected these measures to fill out manually:",
    notifyMeasuresPrompt: "These measures were sent to each recipient automatically",
    notifyMissingRecipientsPrompt: "Intake is complete, but no measures were sent due to missing recipients.",
    surveyComplete: "Survey Complete",
    intakeSuccessful: "All the intake scales for {{ patient }} have been successfully sent or administered.",
    intakeChoicesFailed: "Failed to save intake administration choices",
    careManagerRequired: "Only care manager providers can enroll patients into collaborative care.",
    enrolledTimeAgo: "enrolled {{ enrolledAgo }} ago",
    actions: {
      enroll: "Enroll",
      confirmConsent: "Confirm & Continue Enrollment",
      completeEnrollment: "Complete Enrollment",
      discharge: "Discharge",
      dischargeTitle: "Discharge Patient ({{patient}})",
      dischargeReasonTitle: "Reason",
      dischargeNotes: "Additional Notes",
      dischargeWarning: "Discharging will close any currently active tasks for this patient.",
      finish: "Finish",
      continueIntake: "Continue with Intake",
      intake: "Finish Intake",
      administerSurvey_one: "Administer 1 Survey",
      administerSurvey_other: "Administer {{ count }} Surveys",
      checkSurveyComplete: "Check Complete",
    },
    updateConsentDateDialog: {
      title: "Update Consent Date for Enrollment",
      details: "Select a new consent date for this enrollment:",
      onlyTimeTracked: "Only time tracked after the consent date and time will be counted as billable.",
      bulletHeader: "If the consent date is changed:",
      bullet1:
        "The billable time for all time entries will be recalculated on the basis of the new consent date",
      bullet2:
        "No changes will be made to the enrollment date, and target minutes and other elements of care will remain unchanged",
      confirm: "Update Consent Date",
      abort: "Abandon Change",
      fieldLabel: "Consent Date",
      dateFormat: "MM/dd/yyyy hh:mm aa",
      errorSaving: "An error occurred while saving the enrollment",
    },
  },
  surveyManagement: {
    title: "Measurement Administration",
    loading: "Loading",
    failure: "Failure",
    sortBy: "Sort by",
    ascending: "Ascending",
    descending: "Descending",
    intakeIncomplete: "Complete intake to administer measurements to this patient",
    noInvites:
      "No measurements have been sent or scheduled for this patient or related people. Ensure that related people are present and have a valid phone number or email address.",
    status: {
      complete: "Completed",
      scheduled: "Scheduled",
      sent: "Sent",
      opened: "Opened",
      begun: "Begun",
      canceled: "Canceled",
      expired: "Expired",
      partial: "Partial",
      administrationRequired: "Administration Required",
    },
    statusDisplayStrings: {
      scheduled: "for {{ date }}",
      sent: "at {{ date }}",
      begun: "at {{ date }}",
      opened: "at {{ date }",
      complete: "at {{ date }}",
      canceled: "by {{ name }} at {{ date }}",
    },
    headers: {
      recipient: "Recipient",
      measure: "Measure(s)",
      status: "Status",
      lastSent: "Last Sent",
      expiresOn: "Expires On",
      sentBy: "Sent By",
      actions: "Actions",
      willSendAt: "Scheduled For",
    },
    actions: {
      administerNow: "Start Interview",
      viewReport: "View Report",
      downloadPdf: "Download PDF",
      downloadPdfGenerating: "Generating PDF...",
      downloadPdfTitle: "Mirah Assessment Results",
      sendReminder: "Send Reminder",
      resendAllNotifications: "Resend {{ count }} Notifications",
      resendAllNotificationsTooltip:
        "Only measurements with a status of SENT, BEGUN, or OPENED will be re-sent. Notifications for each measurement may only be sent once every calendar day.",
      cancelMeasurement: "Cancel Measurement",
      cancelMeasurementDescription:
        "Canceling a measure will prevent further notifications for its completion being sent to the respondent. If the respondent opens an existing notification link, they will be sent to their next open and incomplete measure.",
    },
    invitationHistory: {
      title: "Invitation History",
      createdAt: "Created at",
      sentText: "Sent a text notification at",
      sentEmail: "Sent an email notification at",
      scheduled: "Scheduled to send a notification at",
      completed: "The user completed the measure at",
      notCompleted: "The user has not completed the measure",
      begun: "The user began the measure at",
      notBegun: "The user has not begun the measure",
      canceledNoName: "The invitation was canceled at",
      canceled: "canceled the invitation at",
      expired: "The invitation expired at",
      expiresFuture: "The invitation expires at",
      missingDetails: "A notification could not be sent due to missing contact information",
      forbidden: "A notification could not be sent due to the user's notification preference set to 'none'",
      timeline: "Timeline:",
      recipient: "Recipient:",
      measures: "Measure(s):",
      status: "Status:",
      managedExternally:
        "This invitation was administered outside of Mirah and was imported. Some actions may be unavailable.",
    },
  },
  patientMonthlyBilling: {
    tooltip: "The number of possibly billable minutes recorded this month for this patient",
    enrollmentMonthSummaryPdfTitle: "Collaborative Care Monthly Summary",
  },
  caseConsult: {
    requestConsult: {
      title: "Request Consult",
      titleWithName: "Request Consult ({{ patient }})",
      error: "Failed to save consult request",
      fields: {
        requester: "Requesting Provider",
        reason: "Request Reason",
        text: "More details",
      },
    },
    consultList: {
      title: "Case Consults",
      requestsList: "Requested Consults",
      noConsultRequests: "There are no active consultation requests",
      failedToLoadMeetings: "Failed to load consult meetings",
      hiddenConsultRequests_zero: "",
      hiddenConsultRequests_one: "One consultation request is hidden by your panel selection",
      hiddenConsultRequests_other: "{{ count }} consultation requests are hidden by your panel selection",
      hiddenMeetings_zero: "",
      hiddenMeetings_one: "One consult meeting is hidden by your panel selection",
      hiddenMeetings_other: "{{ count }} consult meetings are hidden by your panel selection",
      fields: {
        patient: "Patient",
        requestTime: "Requested",
        requestTimeValueShort: "$t(common:date.durationToNow) ago",
        requestTimeValueLong: "Requested $t(common:date.durationToNow) ago",
        requestedBy: "Requested By",
        reason: "Reason",
        meetingDate: "Date",
        attendees: "Attendees",
        patients: "Patients",
      },
      actions: {
        beginSummary: "Begin Summary",
        editSummary: "Edit Summary",
        instantConsult: "Instant Consult",
        complete: "Mark Complete",
        startConsult: "Start Meeting",
        cancel: "Cancel",
        cancelForm: {
          title: "Cancel Case Consultation for {{ patient }}",
          explanation: "Do you want to cancel the consultation for {{ patient }}?",
          confirm: "Cancel Case Consultation",
          abort: "Keep Case Consultation",
        },
      },
      prepareSummary: {
        title: "Prepare Case Consult Summary for  {{ patient }}",
        loadError: "Failed to load case consult",
        saving: "Saving",
        saveError: "Failed to save summary",
        saved: "Saved",
      },
    },
    consultMeeting: {
      pageTitle: "Consult Meeting",
      consultSummary: {
        title: "Consult Meeting - $t(common:date.long)",
        failedToLoad: "Failed to load consult meeting",
        attendees: "Attendees",
        addAttendee: "Add Attendee",
        finish: "Finish Meeting",
      },
      requestedCases: {
        title: "Requested Consults",
        failedToLoad: "Failed to load requested cases",
        noCases: "There are no requested cases to review",
        meetingEnded:
          "This consult meeting has ended. You can review discussed cases below, or return to the previous page to start a new meeting.",
        beginConsult: "Begin Consultation",
      },
      discussedCases: {
        title: "Discussed Cases",
        failedToLoad: "Failed to load discussed cases",
        noCases:
          "This meeting has not dicussed any cases yet, select one from the requested cases list above to begin",
        caseNotes: "Case Notes",
        completeCase: "Mark Complete",
        viewCase: "View Details",
      },
      return: "Return to Consult Meeting",
    },
    caseConsultReview: {
      pageTitle: "Case Consult",
      requests: {
        title: "Requests",
        requestDate: "Date",
      },
      caseSummary: {
        title: "Case Summary",
        noSummary: "No summary provided",
      },
      patientInfo: {
        title: "Additional Patient Information",
        noNote: "No additional information provided",
      },
      assessments: {
        title: "Assessments",
      },
      discussion: {
        title: "Discussion",
      },
      recommendations: {
        title: "Recommendations",
      },
      autosave: {
        noChanges: "No changes",
        saving: "Saving changes",
        waitingToSave: "Unsaved changes",
        saved: "All changes saved",
        error: "Failed to save changes",
      },
      tasks: {
        title: "Tasks",
        noTasks: "No tasks have been created for this case",
      },
      actions: {
        completeAndDocumented: "Complete and Documented",
        completeNotDocumented: "Complete - Document Later",
        cancel: "Cancel",
      },
    },
    quickText: {
      title: "Case Consult Smart Phrases",
      create: "New Smart Phrase",
      failedToSave: "Failed to save smart phrase",
      fields: {
        title: "Title",
        text: "Text",
      },
    },
  },
  performanceCharts: {
    myPerformance: "My Performance",
    institutePerformance: "Institute Performance",
    acuity: {
      title: "Enrollment Acuity",
      description: "The acuity breakdown of newly enrolled patients during the period.",
      minimal: "Minimal",
      low: "Low",
      mild: "Mild",
      moderate: "Moderate",
      modSevere: "ModSevere",
      severe: "Severe",
    },
    newEnrollments: {
      title: "New Enrollments",
      description: "Total New Enrollments:",
    },
    assessedPatients: {
      title: "Assessed Patients",
      description:
        "Percentage of patients which completed a survey and the results were reviewed by a care manager.",
    },
    caseloadEfficiency: {
      title: "Caseload Efficiency",
      description: "Enrollments which had more than target task minutes logged.",
    },
  },
  panels: {
    title: "Panels",
    titleWithCount_one: "{{ count }} Panel",
    titleWithCount_other: "{{ count }} Panels",
    genericLoadError: "Failed to load panels",
    genericSaveError: "Failed to save panel",
    createPanel: "New Panel",
    unusedProviders: "Some providers are not included in any panel: {{ names }}.",
    selectPanel: "Select Panel(s)",
    fields: {
      panelName: "Name (optional)",
      noPanelSelected: "You must select a panel before selecting team members",
      noRelevantTeamMembers: "This panel does not contain any active team members with this function",
    },
    actions: {
      editName: "Change Name",
      retire: "Retire Panel",
      unretire: "Activate Panel",
    },
    myPanels: {
      title: "My Panels",
      noPanels: "You are not a member of any active panels",
    },
    activePanels: {
      title: "Active Panels",
      noPanels: "There are no active panels you are not a member of",
    },
    retiredPanels: {
      title: "Retired Panels",
      noPanels: "There are no retired panels",
    },
    teamMembers: {
      noMembers: "This panel has no team members",
      name: "Team Member",
      function: "Function",
      dates: "Dates Active",
      startDate: "Start Date",
      endDate: "End Date (optional)",
      genericSaveError: "Failed to save team member",
      actions: {
        create: "New Team Member",
        deactivate: "Deactivate",
        deactivatePerson: "Deactivate {{ name }}",
        addAllPcps: "Add All PCPs",
      },
    },
  },
  timeline: {
    noEvents: "There are no events to display",
    showOlderEventsButton: "Show Older Events",
    badge: {
      top: "This time entry can't count its full minute allotment because it overlaps in time with other time entries. Time entries overlap in time when:",
      firstNote: "They were performed by the same provider",
      secondNote: "They were performed by any provider, but as a part of the same task",
      footer:
        "Adjusting the time to avoid overlaps will allow a time entry to count its full minute allotment.",
    },
    info: {
      patient: "This timeline displays all of the recent tasks and time entries for this patient within the specified date ranges",
      task: "This timeline displays all of the time entries that are a part of this task",
      timeEntry: "This timeline displays this time entry, as well as other time entries nearby in time that could potentially overlap",
    }
  },
  minutesTargets: {
    pageTitle: "Mirah Minutes Targets",
    genericLoadError: "Failed to load minutes targets",
    explanation:
      "When selecting the target for each patient at the start of the month, Mirah will pick a custom target you've defined if one matches. Otherwise, Mirah will use the relevant default target.",
    custom: {
      title: "Custom Minutes Targets",
      empty:
        "There are no custom targets configured for your institute. Your patients will use the default targets below.",
    },
    default: {
      title: "Default Minutes Targets",
    },
    target: {
      oneMonthTitle: "Month {{ month }}",
      twoMonthTitle: "Month {{ start }} - {{ end }}",
      openMonthTitle: "Month {{ start }} and later",
      floor: "On Target Floor: {{ floor }} minutes",
      ceiling: "On Target Ceiling: {{ ceiling }} minutes",
      under: "Under Target",
      on: "On Target",
      over: "Over Target",
    },
  },
  panel: {
    // This will be removed and moved back into standard enum translation once migrated
    enrollmentStatus: {
      enrolled: "Enrolled",
      discharged: "Discharged",
      unenrolled: "Unenrolled",
    },
    title: "Mirah Panel",
    filters: {
      symptoms: "Symptoms",
      intakeGeneral: "Intake General Functioning",
      intakeTargeted: "Intake Targeted Symptom",
      currentGeneral: "Current General Functioning",
      currentTargeted: "Current Targeted Symptom",
      minutes: "Minutes",
      minuteStatus: "This Month Minutes Target",
      discharge: "Discharge Information",
      dischargedAt: "Discharge Date",
      dischargeReason: "Discharge Reasons",
      dischargedAtStart: "Earliest Discharge",
      dischargedAtEnd: "Latest Discharge",
      enrollment: "Enrollment Information",
      enrolledAtStart: "Earliest Enrollment",
      enrolledAtEnd: "Latest Enrollment",
      risk: "Risk",
      firstSuicidalityAlert: "Intake Self-Harm Risk",
      lastSuicidalityAlert: "Latest Self-Harm Risk",
      anySuicidalityAlert: "Highest Self-Harm Risk",
    },
    fields: {
      status: "Status",
      minutes: "Minutes",
      patientName: "Name",
      gender: "Gender",
      dob: "DOB",
      enrollment: "Enrollment",
      pcp: "PCP",
      cm: "CM",
      mrn: "MRN",
      consentDate: "Consent Date",
      enrolledAt: "Enrollment Date",
      dischargedAt: "Discharge Date",
      dischargeReason: "Discharge Reason",
      durationDays: "Duration (Days)",
      durationMonths: "Duration (Months)",
    },
    search: {
      footer_zero: "Total patients matching your filters: {{ fullSearchResultCount }}",
      footer_one:
        "Patients in selected panels matching your filters: {{ searchWithPanelsCount }}. (Total patients: {{ fullSearchResultCount }})",
      footer_other:
        "Patients in selected panels matching your filters: {{ searchWithPanelsCount }}. (Total patients: {{ fullSearchResultCount }})",
    },
  },
  progressBar: {
    failedToLoad: "Current provider has no billed minutes implementation target, progress bar unavailable",
    targetLink: {
      prefix: "View full",
      suffix: "{{ name }} implementation target",
    },
    currentDayLabel: "$t(common:date.dayOfWeek) Hours",
    milestones: {
      halfway: "Keep going!",
      onTrack: "Great!",
    },
    calendar: {
      title: "$t(common:date.month) Hours",
      // Doing these all manually instead of through the date formatter because I don't want to have to do a bunch of
      // date math to get actual date objects for each day.
      sunday: "S",
      monday: "M",
      tuesday: "T",
      wednesday: "W",
      thursday: "T",
      friday: "F",
      saturday: "S",
    },
  },
};

export default collaborativeCare;
