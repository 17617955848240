import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import { minutesToHours } from "date-fns";
import { minutesInHour } from "date-fns/constants";

type MinutesLabelProps = {
  minutes: number;
  hideZero?: boolean;
  typography?: TypographyProps;
};

export function MinutesLabel(props: MinutesLabelProps) {
  return (
    <Typography component={"span"} {...props.typography}>
      {formatMinutes(props.minutes, props.hideZero)}
    </Typography>
  );
}

// Surprisingly, there doesn't seem to be a date-fns formatter that does this. I don't think this will be an i18n
// problem though.
export function formatMinutes(rawMinutes: number, hideZero?: boolean) {
  if (rawMinutes === 0 && hideZero) {
    return "";
  }

  const hours = minutesToHours(rawMinutes);
  const minutes = rawMinutes - hours * minutesInHour;
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes.toString();

  return `${hours}:${minutesStr}`;
}
