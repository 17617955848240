import { Box, DialogContent, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  EnrollmentMonthMinutesTargetStatus,
  usePatientBillableMinutesQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { PatientId } from "Lib/Ids";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import ErrorMessage from "Shared/ErrorMessage";
import { useIsDesktop } from "Shared/Responsive";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { dateToMonth } from "Shared/Month";
import { formatMinutes } from "Shared/MinutesLabel";

type PatientBillableMinutesBadgeProps = {
  patientId: PatientId;
};
export function PatientBillableMinutesBadge(props: PatientBillableMinutesBadgeProps): ReactElement | null {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const { remoteData } = apolloQueryHookWrapper(
    usePatientBillableMinutesQuery({
      variables: {
        monthAndYear: dateToMonth(new Date()),
        patientId: props.patientId,
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => <Typography>{t("common:remoteData.notAsked")}</Typography>,
    Loading: () => <Typography>{t("common:remoteData.loading")}</Typography>,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (response) => {
      const node = response.collaborativeCareEnrollmentMonths?.nodes[0];

      if (!node) {
        // If there is no enrollment month, we're not enrolled so return nothing.
        return null;
      }
      return (
        <PatientBillableMinutesWithTargetBadgeElement
          billableMinutes={node.billableMinutes}
          minutesTargetCeiling={node.minutesTargetCeiling}
          minutesTargetFloor={node.minutesTargetFloor}
          minutesTargetStatus={node.minutesTargetStatus}
        />
      );
    },
  });
}

type BillableMinutesWithTargetBadgeElementProps = {
  billableMinutes: number;
  minutesTargetCeiling?: number;
  minutesTargetFloor?: number;
  minutesTargetStatus?: EnrollmentMonthMinutesTargetStatus;
  label?: string;
};

// Where the indicator will be for zero minutes. 0 is up, so 225 is the bottom left.
const BILLABLE_MINUTES_GAUGE_START_ANGLE = 225;
// How far around the circle the gauge will go.
const BILLABLE_MINUTES_GAUGE_ARC = 270;

// Constructs a conic gradient that shows the relevant pie chart slice of of the billable minutes gauge. This will be
// a wedge shape that goes all the way to the center, but by putting another opaque circle on top of it we'll only show
// the outer rim, making it look similar to a speedometer.
// https://developer.mozilla.org/en-US/docs/Web/CSS/gradient/conic-gradient
function billableMinutesPieChartGradient(minutes: BillableMinutesWithTargetBadgeElementProps, theme: Theme) {
  // If we don't have valid billing ranges show a blank gauge
  if (
    minutes.minutesTargetStatus === undefined ||
    minutes.minutesTargetFloor === undefined ||
    minutes.minutesTargetCeiling === undefined
  ) {
    return "transparent";
  }

  // The over target display has the same size as the under target display
  const endMinutes = minutes.minutesTargetCeiling + minutes.minutesTargetFloor;
  const lowThresholdAngle = (minutes.minutesTargetFloor / endMinutes) * BILLABLE_MINUTES_GAUGE_ARC;
  const highThresholdAngle = (minutes.minutesTargetCeiling / endMinutes) * BILLABLE_MINUTES_GAUGE_ARC;

  const lowColor =
    minutes.minutesTargetStatus === EnrollmentMonthMinutesTargetStatus.UNDER_TARGET
      ? theme.palette.collaborativeCare.patientBillableMinutesUnderTarget
      : "transparent";
  const onColor =
    minutes.minutesTargetStatus === EnrollmentMonthMinutesTargetStatus.ON_TARGET
      ? theme.palette.collaborativeCare.patientBillableMinutesOnTarget
      : "transparent";
  const highColor =
    minutes.minutesTargetStatus === EnrollmentMonthMinutesTargetStatus.OVER_TARGET
      ? theme.palette.collaborativeCare.patientBillableMinutesOverTarget
      : "transparent";

  return `conic-gradient(
    from ${BILLABLE_MINUTES_GAUGE_START_ANGLE}deg,
    ${lowColor} ${lowThresholdAngle}deg,
    ${onColor} ${lowThresholdAngle}deg ${highThresholdAngle}deg,
    ${highColor} ${highThresholdAngle}deg ${BILLABLE_MINUTES_GAUGE_ARC}deg,
    transparent ${BILLABLE_MINUTES_GAUGE_ARC}deg
  )`;
}

function billableMinutesIndicatorRotation(minutes: BillableMinutesWithTargetBadgeElementProps) {
  if (minutes.minutesTargetFloor === undefined || minutes.minutesTargetCeiling === undefined) {
    return BILLABLE_MINUTES_GAUGE_START_ANGLE;
  }

  // The over target display has the same size as the under target display
  const endMinutes = minutes.minutesTargetCeiling + minutes.minutesTargetFloor;
  // If you have more than end minutes logged, peg the gauge don't go past.
  const effectiveMinutes = Math.min(minutes.billableMinutes, endMinutes);

  return (
    ((effectiveMinutes / endMinutes) * BILLABLE_MINUTES_GAUGE_ARC + BILLABLE_MINUTES_GAUGE_START_ANGLE) % 360
  );
}

export function PatientBillableMinutesWithTargetBadgeElement(
  props: BillableMinutesWithTargetBadgeElementProps
): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  const theme = useTheme();
  const [mobileDialogOpen, setMobileDialogOpen] = React.useState(false);
  const isDesktop = useIsDesktop();

  const indicator = (
    <Box
      sx={{
        position: "absolute",
        width: "2px",
        // This is the length of the indicator line
        height: "0.75rem",
        left: "50%",
        // If you want the indicator to stick out of gauge, set this to a negative number
        top: "0",
        backgroundColor: theme.palette.common.black,
        transform: `rotate(${billableMinutesIndicatorRotation(props)}deg)`,
        // The second number here must be (outer_box_height - indicator_top) / 2 in order to rotate the indicator
        // around the center of the badge
        transformOrigin: "0 1.5rem",
      }}
    />
  );

  const badge = (
    <Box
      display="flex"
      position="relative"
      // Using fixed heigh/width instead of minHeight/minWidth so that the badge doesn't unexpectedly change shape when
      // its container changes size/display/positioning. This puts us at risk of overflowing with very large amounts of
      // minutes, but it doesn't seem to stretch up to 4 digits so I think we're okay.
      height="3rem"
      width="3rem"
      alignItems="center"
      justifyContent="center"
      borderRadius="1.5rem"
      onClick={() => setMobileDialogOpen(true)}
      sx={{ background: billableMinutesPieChartGradient(props, theme) }}
    >
      <Box
        display="flex"
        borderRadius="50%"
        bgcolor={theme.palette.collaborativeCare.patientBillableMinutes}
        // The difference between this width/height and the outer width/height is how wide the gauge will be.
        height="2.5rem"
        width="2.5rem"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body1" fontSize="0.85rem" color={theme.palette.common.black}>
          {props.label || formatMinutes(props.billableMinutes)}
        </Typography>
      </Box>
      {/* If we don't have a target we don't want to have an indicator that points at nothing. */}
      {props.minutesTargetFloor ? indicator : null}
    </Box>
  );

  const tooltipContent = <PatientBillableMinutesToolip {...props} />;

  if (isDesktop) {
    return <Tooltip title={tooltipContent}>{badge}</Tooltip>;
  } else {
    return (
      <>
        {badge}
        <ResponsiveDialog
          title={t("collaborativeCare:patientDetails.billableMinutesBadge.title")}
          open={mobileDialogOpen}
          onClose={() => setMobileDialogOpen(false)}
        >
          <DialogContent>{tooltipContent}</DialogContent>
        </ResponsiveDialog>
      </>
    );
  }
}

function PatientBillableMinutesToolip(props: BillableMinutesWithTargetBadgeElementProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const { billableMinutes, minutesTargetCeiling, minutesTargetFloor } = props;

  if (minutesTargetCeiling && minutesTargetFloor) {
    return (
      <Typography>
        {t("collaborativeCare:patientDetails.billableMinutesBadge.explanation", {
          billableMinutes,
          minutesTargetCeiling,
          minutesTargetFloor,
        })}
      </Typography>
    );
  } else {
    return (
      <Typography>
        {t("collaborativeCare:patientDetails.billableMinutesBadge.explanationNoTarget", {
          billableMinutes,
        })}
      </Typography>
    );
  }
}
