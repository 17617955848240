import React, { ReactElement } from "react";
import { TaskDetails, TimeEntryDetails } from "./TaskCard";
import { Alert, Box, Divider, Grid, Stack, TextField, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  TimeEntryLogDurationReviewStatus,
  useAddNoteToTaskMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { Form, FormOverlay, useForm, useTextField } from "Shared/Form";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { TaskId } from "Lib/Ids";
import { TimeEntryEditIconButton } from "CollaborativeCare/TimeEntry/TimeEntryEditIconButton";
import { TimeEntryLogDeleteIconButton } from "CollaborativeCare/TimeEntry/TimeEntryDeleteIconButton";
import { refetchQueries } from "Lib/RefetchQueries";
import { useIsMobile } from "Shared/Responsive";
import EditableTaskBody from "./EditableTaskBody";
import { EditableTaskCardNote } from "./EditableTaskCardNote";
import { formatMinutes } from "Shared/MinutesLabel";

export function TaskCardBody({ task }: { task: TaskDetails }): ReactElement {
  let bodyContent = <EditableTaskBody task={task} />;
  if (task.isPlaceholder) {
    bodyContent = <></>;
  }
  return (
    <Stack direction="column" spacing={1}>
      {bodyContent}
      <AddNoteForm taskId={task.id} />
      <TaskLogs task={task} />
    </Stack>
  );
}

function AddNoteForm(props: { taskId: TaskId }): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [addNote, { remoteData, reset }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareAddNoteToTask,
    useAddNoteToTaskMutation({
      refetchQueries: refetchQueries("tasks"),
    })
  );

  const fields = {
    text: useTextField({ required: true }),
  };

  const form = useForm({
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      addNote({
        variables: {
          input: {
            // Safe cast because validation has passed when we get here
            text: fields.text.value as string,
            taskId: props.taskId,
          },
        },
      });
    },
  });

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        // Let the success overlay linger for a bit so they get the feedback it worked (the note will also show up
        // inline), then clear the form.
        setTimeout(() => {
          reset();
          form.reset();
        }, 500);
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const columns = useIsMobile() ? 6 : 12;
  const flexBox = useIsMobile() ? null : <Box sx={{ flexGrow: 1 }} />;

  return (
    <Form onSubmit={form.onSubmit}>
      <FormOverlay response={remoteData} errorMessage={t("collaborativeCare:tasks.genericNoteFormError")} />
      <Grid container columns={columns} spacing={1}>
        <Grid item xs={9}>
          <TextField
            label={t("collaborativeCare:tasks.newNote")}
            multiline
            minRows={1}
            value={fields.text.value}
            onChange={fields.text.onChange}
            error={fields.text.error}
            helperText={fields.text.helperText}
            sx={{ flexGrow: 1 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" alignItems="center">
            {flexBox}
            <ButtonWithSpinner
              variant="contained"
              type="submit"
              color="secondary"
              showSpinner={form.showSpinner}
              disabled={form.disableSubmit}
              sx={{ marginTop: "0.6em" }}
            >
              {t("collaborativeCare:tasks.actions.addNote")}
            </ButtonWithSpinner>
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
}

type TaskLogsProps = {
  task: Pick<TaskDetails, "notes" | "timeEntries" | "id" | "title" | "patient" | "isPlaceholder">;
};

function TaskLogs(props: TaskLogsProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const theme = useTheme();

  const nonEmptyTimeEntries = props.task.timeEntries.nodes.filter(
    (entry) => entry.durationSeconds !== null || entry.endTime !== null
  );

  const notesAndTimeEntries = [...props.task.notes.nodes, ...nonEmptyTimeEntries];
  notesAndTimeEntries.sort((left, right) => {
    // Notes are considered to have "happened" when they were written, time entries when they were started. This is a
    // log of what happened when, not a log when we recorded what happened.
    let leftTime = null;
    if (left.__typename === "Note") {
      if (left.timeEntry) {
        leftTime = left.timeEntry.startTime;
      } else {
        leftTime = left.createdAt;
      }
    } else {
      leftTime = left.startTime;
    }
    let rightTime = null;
    if (right.__typename === "Note") {
      if (right.timeEntry) {
        rightTime = right.timeEntry.startTime;
      } else {
        rightTime = right.createdAt;
      }
    } else {
      rightTime = right.startTime;
    }

    // in the case of a tie between a time entry and a note, put the time entry above the note
    if (left.__typename != right.__typename && rightTime.toString() == leftTime.toString()) {
      if (left.__typename == "TimeEntryLog") {
        return -1;
      } else {
        return 1;
      }
    }

    // Reverse chronological order, like a blog.
    return leftTime < rightTime ? 1 : -1;
  });

  const noLogs = <Typography fontStyle="italic">{t("collaborativeCare:tasks.noLogs")}</Typography>;

  return (
    <Stack direction="column" spacing={1} paddingTop={1}>
      <Typography variant="h2" borderBottom={`1px solid ${theme.palette.dividerLight}`}>
        {t("collaborativeCare:tasks.logsTitle")}
      </Typography>
      <Stack
        direction="column"
        spacing={1}
        divider={<Divider sx={{ borderColor: theme.palette.dividerChip }} />}
      >
        {notesAndTimeEntries.length === 0 ? noLogs : null}
        {notesAndTimeEntries.map((noteOrEntry) => {
          if (noteOrEntry.__typename === "Note") {
            return <EditableTaskCardNote note={noteOrEntry} key={noteOrEntry.id.toString()} />;
          } else {
            return (
              <TaskCardTimeEntry
                timeEntryLog={noteOrEntry}
                key={noteOrEntry.id.toString()}
                task={props.task}
              />
            );
          }
        })}
      </Stack>
    </Stack>
  );
}

type TaskCardTimeEntryProps = {
  timeEntryLog: TimeEntryDetails;
  task: Pick<TaskDetails, "id" | "title" | "patient" | "isPlaceholder">;
};

function TaskCardTimeEntry(props: TaskCardTimeEntryProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const minutes = props.timeEntryLog.durationSeconds ? props.timeEntryLog.durationSeconds / 60 : 0;
  const endTimeContent = props.timeEntryLog.endTime ? (
    <Typography fontWeight="bold" component="span">
      {t("common:date.time", { date: props.timeEntryLog.endTime })}
    </Typography>
  ) : null;

  const updatedTimeEntryLog = {
    __typename: "TimeEntryLog" as const,
    id: props.timeEntryLog.id,
    clientStartTime: props.timeEntryLog.startTime,
    durationSeconds: props.timeEntryLog.durationSeconds,
    durationReviewStatus: props.timeEntryLog.durationReviewStatus,
    reviewedAt: props.timeEntryLog.reviewedAt,
    unreviewedDurationSeconds: props.timeEntryLog.unreviewedDurationSeconds,
  };

  let timeEntryLogContent = (
    <>
      <Typography component="div">
        {props.timeEntryLog.provider.name}, {t("common:date.long", { date: props.timeEntryLog.startTime })}
      </Typography>

      <Typography fontWeight="bold" component="span">
        {t("collaborativeCare:tasks.timeEntryLogMinutes", {
          hours: formatMinutes(minutes),
        })}
      </Typography>
      <Typography fontWeight="bold" component="span">
        &nbsp;{t("common:date.time", { date: props.timeEntryLog.startTime })}
      </Typography>
      <Typography component="span">&nbsp;-&nbsp;</Typography>
      {endTimeContent}
    </>
  );

  if (props.timeEntryLog.durationReviewStatus === TimeEntryLogDurationReviewStatus.NEEDS_REVIEW) {
    timeEntryLogContent = <Alert severity="warning">{timeEntryLogContent}</Alert>;
  } else {
    timeEntryLogContent = <Box paddingLeft="3.25rem">{timeEntryLogContent}</Box>;
  }

  // There's some fuckery with all the layers of stuff above this that causes these buttons
  // to break out of their box pretty easily and it looks really bad. We're going to stack them
  // vertically purely via grid when on desktop, but use the old row stacking on mobile.
  const timeEntryEditButton = (
    <TimeEntryEditIconButton
      timeEntryLog={updatedTimeEntryLog}
      task={{ id: props.task.id, title: props.task.title, isPlaceholder: props.task.isPlaceholder }}
      patient={props.task.patient}
      variant="outlined"
    />
  );
  const timeEntryDeleteButton = (
    <TimeEntryLogDeleteIconButton
      timeEntryLog={updatedTimeEntryLog}
      patient={props.task.patient}
      variant="outlined"
    >
      {timeEntryLogContent}
    </TimeEntryLogDeleteIconButton>
  );
  let timeEntryEditButtons = (
    <Box flexBasis="10rem">
      <Stack direction="column" spacing={0.5} alignItems="start">
        {timeEntryEditButton}
        {timeEntryDeleteButton}
      </Stack>
    </Box>
  );
  if (useIsMobile()) {
    timeEntryEditButtons = (
      <Stack direction="row-reverse" spacing={1} marginTop="1em">
        {timeEntryDeleteButton}
        {timeEntryEditButton}
      </Stack>
    );
  }

  const mainDirection = useIsMobile() ? "column" : "row";

  return (
    <Stack direction={mainDirection} spacing={1}>
      <Box flexGrow={1}>{timeEntryLogContent}</Box>
      {timeEntryEditButtons}
    </Stack>
  );
}
