import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Patient,
  Task,
  TimeEntryLog,
  useTimelineEditTimeEntryLogQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { CSSProperties, ReactElement, useState } from "react";
import { PickTypename } from "type-utils";
import {
  durationMinutesToSeconds,
  ModifyTimerForm,
  OutsideEnrollmentNotification,
  TimeEntryReviewAlert,
  useTimeEntryLogEditForm,
} from "./ModifyTimerForm";
import { useTranslation } from "react-i18next";
import { TIMELINE_FLAG, WithFrontendFlag, WithoutFrontendFlag } from "Contexts/FrontendFlagContext";
import { TimeEntryLogId } from "Lib/Ids";
import { useCurrentProviderId } from "AppSession/AppSession";
import { DateTimePicker } from "@mui/x-date-pickers";
import { addMinutes } from "date-fns";
import { Form, FormOverlay } from "Shared/Form";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { DeleteTimeEntryButton } from "./DeleteTimeEntryButton";
import { EventDialogType, Timeline, TimelineEvent } from "CollaborativeCare/Timeline";
import PlaceholderOptions from "CollaborativeCare/Tasks/PlaceholderOptions";
import { useCurrentTask } from "Contexts/CurrentTaskContext";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import InfoIcon from '@mui/icons-material/Info';

type TimeEntryLogEditDialogProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  task: Pick<Task, "id" | "title" | "isPlaceholder"> & {
    patient: PickTypename<Patient, "id"> | null;
  };
  timeEntryLog: PickTypename<
    TimeEntryLog,
    | "id"
    | "durationSeconds"
    | "clientStartTime"
    | "durationReviewStatus"
    | "reviewedAt"
    | "unreviewedDurationSeconds"
  >;
  allowDiscardTime?: boolean;
};

// This component just serves as a general purpose wrapper around only the modify timer form.
// This lets us uniformly handle showing the edit form regardless of call site.
export function TimeEntryLogEditDialog(props: TimeEntryLogEditDialogProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  const title = t("collaborativeCare:tasks.timeEntryLog.modifyTimeHeader") + ": " + props.task.title;
  const allowDiscardTime = props.allowDiscardTime !== undefined ? props.allowDiscardTime : true;

  return (
    <>
      <WithoutFrontendFlag flagName={TIMELINE_FLAG}>
        <ResponsiveDialog open={props.open} onClose={props.onClose} title={title} dialogWidth="50%">
          <DialogContent>
            <ModifyTimerForm
              taskId={props.task.id}
              isPlaceholder={props.task.isPlaceholder}
              patientId={props.task.patient?.id}
              timeEntryLog={props.timeEntryLog}
              onSuccess={props.onSuccess}
            />
          </DialogContent>
        </ResponsiveDialog>
      </WithoutFrontendFlag>
      <WithFrontendFlag flagName={TIMELINE_FLAG}>
        <TimeEntryLogEditDialogForm
          open={props.open}
          onClose={props.onClose}
          onSuccess={props.onSuccess}
          task={props.task}
          timeEntryLog={props.timeEntryLog}
          allowDiscardTime={allowDiscardTime}
        />
      </WithFrontendFlag>
    </>
  );
}

type TimeEntryLogEditDialogFormProps = TimeEntryLogEditDialogProps;

function TimeEntryLogEditDialogForm(props: TimeEntryLogEditDialogFormProps): ReactElement {
  // We want a different enough presentation from the old ModifyTimerForm, so here we are.
  
  // We're going to allow this form to be reused with different TEL's as the user clicks timeline items.
  // To handle that, we just need to replace the time entry id we're currently viewing and everything else
  // is keyed off of that.
  const [timeEntryLogId, setTimeEntryLogId] = useState<TimeEntryLogId>(props.timeEntryLog.id)
  const onReplace = (timeEntryLogId: TimeEntryLogId) => {
    setTimeEntryLogId(timeEntryLogId)
  }

  // Due to react vagaries, we need to manually set our TEL back to whatever it was originally.
  // This is because if the props coming into this call do not change, then react won't recompute
  // the initial useState nor any useEffect, and the timeEntryLogId will remain whatever it was
  // when this modal was last opened. This comes up if the user clicks an event to open this modal,
  // clicks on a different event to swap to it, closes the modal, then reopens the modal with the
  // the initial event again - no prop change, wrong event selected. By setting this back to the original
  // event, in the rogue case we'll have the correct event selected. If the user selects a different
  // original event, we are safe, because the useState call will be re-evaluated correctly.
  const onClose = () => {
    setTimeEntryLogId(props.timeEntryLog.id)
    props.onClose()
  }

  const { remoteData } = apolloQueryHookWrapper(
    useTimelineEditTimeEntryLogQuery({
      variables: {
        timeEntryLogId: timeEntryLogId,
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => {
      return <TimelineCardSkeleton />;
    },
    Loading: () => {
      return <TimelineCardSkeleton />;
    },
    Failure: () => {
      return <TimelineCardListError />;
    },
    Success: (result) => {
      if (!result.collaborativeCareTimeEntryLog) {
        return <TimelineCardListError />;
      }

      return <TimeEntryLogEditDialogFormElement 
        open={props.open} 
        onClose={onClose}
        onSuccess={props.onSuccess}
        onReplace={onReplace}
        task={result.collaborativeCareTimeEntryLog.workFor}
        timeEntryLog={result.collaborativeCareTimeEntryLog}
        allowDiscardTime={props.allowDiscardTime} />
    }
  });
}

type TimeEntryLogEditDialogFormElementProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  task: Pick<Task, "id" | "title" | "isPlaceholder"> & {
    patient: PickTypename<Patient, "id"> | null;
  };
  timeEntryLog: PickTypename<
    TimeEntryLog,
    | "id"
    | "durationSeconds"
    | "clientStartTime"
    | "durationReviewStatus"
    | "reviewedAt"
    | "unreviewedDurationSeconds"
  >;
  allowDiscardTime?: boolean;
  onReplace?: (timeEntryLogId: TimeEntryLogId) => void;
};

// This boxes the actual content outside of the query we'll use and replace this component with.
// This is mostly for hook reasons.
function TimeEntryLogEditDialogFormElement(props: TimeEntryLogEditDialogFormElementProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const currentProviderId = useCurrentProviderId();
  if (!currentProviderId) {
    // This breaks the hooks model in that we won't use the later ones, but providers also
    // can't both be and not be when this component renders, so it's a moot problem for now.
    // If we do end up needing this we'll need to rebox these into new components.
    return <></>;
  }

  const defaultDurationMinutes =
    props.timeEntryLog.durationSeconds === null
      ? undefined
      : Math.round(props.timeEntryLog.durationSeconds / 60);

  const { form, fields, remoteData, resetMutation } = useTimeEntryLogEditForm(
    props.timeEntryLog.clientStartTime,
    defaultDurationMinutes,
    currentProviderId,
    props.timeEntryLog.id,
    () => {
      setTimeout(() => {
        closeForm();
      }, 500);
    }
  );

  const closeForm = () => {
    resetMutation();
    form.reset();
    props.onSuccess();
  };

  const title = t("collaborativeCare:tasks.timeEntryLog.modifyTimeHeader") + ": " + props.task.title;

  // We're going to use the duration to infer the end time rather than let someone choose it,
  // so we'll have to calculate when that actually is.
  const startDate = fields.clientStartTime.value as Date;
  const seconds = durationMinutesToSeconds(fields.durationMinutes.value);
  const minutes = seconds ? seconds / 60 : 0; // We need a possible zero, and duration can be undefined.
  const endTime = addMinutes(startDate, minutes);

  const [showTimeEntryForm, setShowTimeEntryForm] = useState<boolean>(true);

  let formContent = (
    <>
      <DateTimePicker
        label={t("collaborativeCare:fields.manualStartTime.label")}
        format={t("collaborativeCare:tasks.timeEntryLog.dateFormat")}
        value={fields.clientStartTime.value}
        onChange={fields.clientStartTime.onChange}
        slotProps={{
          textField: {
            error: fields.clientStartTime.error,
            helperText: fields.clientStartTime.helperText,
          },
        }}
      />
      <TextField
        title={t("collaborativeCare:fields.duration.label")}
        label={t("collaborativeCare:fields.duration.label")}
        autoFocus
        value={minutes.toFixed(0)}
        onChange={fields.durationMinutes.onChange}
        error={fields.durationMinutes.error}
        helperText={fields.durationMinutes.helperText}
        type="number"
        InputProps={{ inputProps: { min: 1 } }}
      />
      <DateTimePicker
        label="End Time"
        format={t("collaborativeCare:tasks.timeEntryLog.dateFormat")}
        value={endTime}
        disabled
      />
      <TextField
        multiline
        minRows={5}
        title={t("collaborativeCare:tasks.timeEntryLog.noteText.label")}
        label={t("collaborativeCare:tasks.timeEntryLog.noteText.label")}
        autoFocus
        value={fields.noteText.value}
        onChange={fields.noteText.onChange}
        error={fields.noteText.error}
        helperText={fields.noteText.helperText}
      />
    </>
  );
  if (!showTimeEntryForm) {
    formContent = <></>;
  }

  let placeholderOptionsContent = <></>;
  const displayedTask = useCurrentTask() || props.task;
  if (displayedTask.isPlaceholder && props.task.patient?.id) {
    placeholderOptionsContent = (
      <PlaceholderOptions
        patientId={props.task.patient.id}
        taskId={displayedTask.id}
        setShowSurroundingForms={setShowTimeEntryForm}
      />
    );
  }

  // This is so we can bind the update button to the underlying form. In practice, this
  // doesn't seem to be required, but just for future proofing we'll make sure our forms
  // have unique ids.
  const formId = `tel-edit-${props.timeEntryLog.id.toString()}`;

  // Note that there's issues with wrapping a Form around a ResponsiveDialog. The form seems
  // to need to be inside the DialogContent but cannot also wrap the DialogButtons without
  // causing rendering issues for other elements. As such, we're going to minimally wrap
  // the actual form content and let the submit button bind to the form by id.
  const leftPaneContent = (
    <>
      <TimeEntryReviewAlert timeEntryLog={props.timeEntryLog} />
      <Form id={formId} onSubmit={form.onSubmit}>
        {placeholderOptionsContent}

        <FormOverlay
          response={remoteData}
          errorMessage={form.globalError || t("collaborativeCare:tasks.genericFormError")}
        />
        <Stack direction="column" spacing={1}>
          <Box marginBottom={"0.5em"} fontSize={"small"}>
            <OutsideEnrollmentNotification
              patientId={displayedTask.patient?.id}
              clientStartTime={props.timeEntryLog.clientStartTime}
            />
          </Box>
          {formContent}
        </Stack>
      </Form>
    </>
  );

  // We only want to show the instant discard time button in certain contexts, namely
  // a freshly created TEL.
  let discardTimeEntryButton = <></>;
  if (props.allowDiscardTime) {
    discardTimeEntryButton = (
      <DeleteTimeEntryButton
        variant="outlined"
        color="secondary"
        onSuccess={props.onSuccess}
        timeEntryLogId={props.timeEntryLog.id}
        buttonLabel={t("collaborativeCare:tasks.timeEntryLog.deleteTimeEntryButtonLabel")}
      />
    );
  }

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      hideTitle={true}
      dialogWidth="100%"
      dialogMaxWidth="85%"
      dialogPadding="0"
      dialogBackgroundColor={theme.palette.background.default}
    >
      <DialogContent>
        <Grid container columns={14} spacing={1}>
          <Grid item lg={6} xs={14}>
            <Card sx={{ height: "100%" }}>
              <CardHeader title={title} sx={{ paddingBottom: "0" }} />
              <CardContent>{leftPaneContent}</CardContent>
            </Card>
          </Grid>
          <Grid item lg={8} xs={14}>
            <TimeEntryLogEditDialogTimelineCard timeEntryLogId={props.timeEntryLog.id} onReplace={props.onReplace} />
          </Grid>
        </Grid>
      </DialogContent>

      <Paper sx={{ marginTop: "1em" }}>
        <DialogActions>
          <Stack direction="row" spacing={1} flex={1}>
            <Button
              sx={{ marginLeft: "1em" }}
              onClick={() => {
                closeForm();
              }}
            >
              <KeyboardArrowLeft /> {t("common:actions.back")}
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <ButtonWithSpinner
              variant="contained"
              color="secondary"
              type="submit"
              form={formId}
              showSpinner={form.showSpinner}
              disabled={form.disableSubmit}
            >
              {t("collaborativeCare:tasks.timeEntryLog.modifyTimeSave")}
            </ButtonWithSpinner>
            {discardTimeEntryButton}
          </Stack>
        </DialogActions>
      </Paper>
    </ResponsiveDialog>
  );
}

type TimeEntryLogEditDialogTimelineCardProps = {
  timeEntryLogId: TimeEntryLogId;
  onReplace?: (timeEntryLogId: TimeEntryLogId) => void;
};

function TimeEntryLogEditDialogTimelineCard(props: TimeEntryLogEditDialogTimelineCardProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const cardContentStyle: CSSProperties = {
    overflow: "auto",
  };

  // TODO: We'll actually want to make a new query that gets all of the things that might conflict with this TEL,
  // as well as surrounding neighbors to ease picking a new time.
  // A relatively straightforward way would be:
  // +- 3 hours
  // Same task
  // Same provider
  // We already have the overlap detection functions on the server for pulling this data, and I think it actually
  // makes the most sense to simply expose a variation of those directly onto the TEL type as fields for the
  // purposes of answering this query.
  const { remoteData } = apolloQueryHookWrapper(
    useTimelineEditTimeEntryLogQuery({
      variables: {
        timeEntryLogId: props.timeEntryLogId,
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => {
      return <TimelineCardSkeleton />;
    },
    Loading: () => {
      return <TimelineCardSkeleton />;
    },
    Failure: () => {
      return <TimelineCardListError />;
    },
    Success: (result) => {
      if (!result.collaborativeCareTimeEntryLog) {
        return <TimelineCardListError />;
      }

      // Our actual set of events for this view is this TEL, and also the neighboring TEL's.
      const events: Array<TimelineEvent> = [result.collaborativeCareTimeEntryLog]
      events.push(...result.collaborativeCareTimeEntryLog.neighboringTimeEntryLogs)

      const action = <Tooltip title={t("collaborativeCare:timeline.info.timeEntry")}><InfoIcon /></Tooltip>

      return (
        <Card sx={{ height: "100%" }}>
          <CardHeader title={t("collaborativeCare:patientDetails.cards.timeline")} action={action} />
          <CardContent style={cardContentStyle}>
            <Timeline events={events} 
              eventDialogType={EventDialogType.TimeEntryLogReplace} 
              onReplace={props.onReplace}
              highlightEventId={props.timeEntryLogId} />
          </CardContent>
        </Card>
      );
    },
  });
}

function TimelineCardSkeleton(): ReactElement {
  return <Skeleton variant="rectangular" height="10em" />;
}

function TimelineCardListError(): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  return <Typography>{t("collaborativeCare:patientDetails.timeline.failure")}</Typography>;
}
