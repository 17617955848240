import { CelebrationOutlined, DirectionsRun, ThumbUpOffAltSharp } from "@mui/icons-material";
import {
  Box,
  DialogContent,
  Grid,
  Grow,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  lighten,
  useTheme,
} from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useCurrentProviderId } from "AppSession/AppSession";
import { EntityPathIcon } from "Entities/EntityPath";
import {
  ChartData,
  Entity,
  ImplementationTarget,
  ImplementationTargetReportGraphDataSeries,
  ImplementationTargetReportPeriod,
  ImplementationTargetStatus,
  ImplementationTargetType,
  useImplementationTargetDashboardQuery,
  useImplementationTargetReportDataDetailsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectSimpleCompare, usePrevious } from "Lib/Hooks";
import { EntityId, ImplementationTargetId, ProviderId } from "Lib/Ids";
import Link from "MDS/Link";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { MinutesLabel } from "Shared/MinutesLabel";
import { useIsMobile } from "Shared/Responsive";
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isAfter,
  isBefore,
  isFirstDayOfMonth,
  isSameDay,
  isWeekend,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";
import { range } from "ramda";
import React, { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

export function MinutesDailyProgress(): ReactElement | null {
  const providerId = useCurrentProviderId();

  if (providerId) {
    return <MinutesDailyProgressWithProvider providerId={providerId} />;
  } else {
    return <MinutesDailyProgressError />;
  }
}

type MinutesDailyProgressWithProviderProps = {
  providerId: ProviderId;
};

function MinutesDailyProgressWithProvider(props: MinutesDailyProgressWithProviderProps) {
  // Assume that everyone gets logged out overnight so we don't need to update this while it's still mounted.
  const today = React.useMemo(() => new Date(), []);

  const { remoteData } = apolloQueryHookWrapper(
    useImplementationTargetDashboardQuery({
      variables: {
        entityQuery: {
          // EntityId is really any id of anything that can be an entity.
          entityTreeNodeParams: { entityId: props.providerId as unknown as EntityId },
          exact: true,
        },
        targetType: [ImplementationTargetType.COCM_MONTHLY_BILLED_MINUTES],
        date: today,
        status: [ImplementationTargetStatus.ACTIVE],
        availableForNode: null,
        hideProviderInformation: false,
        includeMirahInternal: false,
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => <MinutesDailyProgressLoading />,
    Loading: () => <MinutesDailyProgressLoading />,
    Failure: (_err) => <MinutesDailyProgressError />,
    Success: (response) => {
      if (response.implementationTargets?.nodes[0]) {
        return (
          <MinutesDailyProgressWithTarget
            today={today}
            providerId={props.providerId}
            target={response.implementationTargets.nodes[0]}
          />
        );
      } else {
        return <MinutesDailyProgressError />;
      }
    },
  });
}

function MinutesDailyProgressLoading() {
  // We know what day it is today so we could in theory split this up into boxes for each day, but I don't think it's
  // worth it. The skeleton takes up the space of the bar+boxes and the margin leaves space for the labels.
  return <Skeleton width="100%" height="3rem" sx={{ marginTop: "1.5rem" }} />;
}

function MinutesDailyProgressError() {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <Stack direction="column" alignItems="center" justifyContent="center">
      {t("collaborativeCare:progressBar.failedToLoad")}
    </Stack>
  );
}

type MinutesDailyProgressWithTargetProps = {
  today: Date;
  providerId: ProviderId;
  target: PickTypename<ImplementationTarget, "id" | "target">;
};

function MinutesDailyProgressWithTarget(props: MinutesDailyProgressWithTargetProps) {
  const { remoteData } = apolloQueryHookWrapper(
    useImplementationTargetReportDataDetailsQuery({
      variables: {
        implementationTargetId: props.target.id,
        entityTreeNode: {
          // EntityId is actually any id from an entity, so this cast is safe.
          entityId: props.providerId as unknown as EntityId,
        },
        dateAndPeriod: {
          endDate: endOfWeek(props.today),
          period: ImplementationTargetReportPeriod.WEEK,
        },
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => <MinutesDailyProgressLoading />,
    Loading: () => <MinutesDailyProgressLoading />,
    Failure: (_err) => <MinutesDailyProgressError />,
    Success: (response) => {
      if (response.implementationTargetReport) {
        const dataSeries = response.implementationTargetReport.graphData.find(
          (series) => series.seriesType === ImplementationTargetReportGraphDataSeries.DATA
        );

        if (dataSeries && props.target.target) {
          return (
            <MinutesDailyProgressWithData
              today={props.today}
              points={dataSeries.points}
              targetMinutes={props.target.target}
              targetId={props.target.id}
              targetEntity={response.implementationTargetReport.entityTreeNode.entity}
            />
          );
        } else {
          return <MinutesDailyProgressError />;
        }
      } else {
        return <MinutesDailyProgressError />;
      }
    },
  });
}

type MinutesDailyProgressWithDataProps = {
  today: Date;
  points: ReadonlyArray<PickTypename<ChartData, "date" | "value">>;
  // FUTURE: If we wanted to future proof this somewhat to handle targets that change for half days and four day weeks
  //         and weekends and such, pass the target points array here instead of a single number. Probably then pass
  //         the targets array into RandomAccessDailyValues and have that compute the percent as well as the raw.
  targetMinutes: number;
  targetId: ImplementationTargetId;
  targetEntity: PickTypename<Entity, "name" | "entityType">;
};

function MinutesDailyProgressWithData(props: MinutesDailyProgressWithDataProps) {
  const { t } = useTranslation(["collaborativeCare"]);

  // This gets all weekdays that aren't today. If you look at the component on a weekday, you'll see a big progress
  // thing for today, and four small boxes for the rest of the week. If you look at it on a weekend, you'll also get
  // the full progress bar for today and five small boxes for the week.
  const otherWeekdays = eachDayOfInterval({
    start: startOfWeek(props.today),
    end: endOfWeek(props.today),
  }).filter((day) => !isWeekend(day) && !isSameDay(day, props.today));
  const earlierDays = otherWeekdays.filter((day) => isBefore(day, props.today));
  const laterDays = otherWeekdays.filter((day) => isAfter(day, props.today));

  const dailyValues = new RandomAccessDailyValues(props.points);

  const isMobile = useIsMobile();
  const [mobileDialogOpen, setMobileDialogOpen] = React.useState(false);

  const tooltipContent = (
    <Stack direction="column" spacing={3} alignItems="center">
      <MinutesDailyProgressCalendarMonth
        today={props.today}
        dailyValues={dailyValues}
        targetMinutes={props.targetMinutes}
      />
      <Link to={`/app/cocm/implementation/${props.targetId}`}>
        <Stack direction="row" spacing={0.25}>
          <span>{t("collaborativeCare:progressBar.targetLink.prefix")}</span>
          <EntityPathIcon entityType={props.targetEntity.entityType} />
          <span>{t("collaborativeCare:progressBar.targetLink.suffix", props.targetEntity)}</span>
        </Stack>
      </Link>
    </Stack>
  );

  const progressBar = (
    <Stack
      direction="row"
      spacing={0.5}
      alignItems="start"
      useFlexGap
      onClick={() => setMobileDialogOpen(true)}
      sx={{
        cursor: isMobile ? "pointer" : "auto",
      }}
    >
      {earlierDays.map((day) => (
        <MinutesProgressBoxWithDayLabel
          key={day.toISOString()}
          dailyValues={dailyValues}
          day={day}
          targetMinutes={props.targetMinutes}
        />
      ))}
      <CurrentDayProgressBarWithLabel
        today={props.today}
        dailyValues={dailyValues}
        targetMinutes={props.targetMinutes}
      />
      {laterDays.map((day) => (
        <MinutesProgressBoxWithDayLabel
          key={day.toISOString()}
          dailyValues={dailyValues}
          day={day}
          targetMinutes={props.targetMinutes}
        />
      ))}
    </Stack>
  );

  if (isMobile) {
    return (
      <>
        {progressBar}
        <ResponsiveDialog
          title={`${props.targetEntity.name} Monthly Hours`}
          open={mobileDialogOpen}
          onClose={() => setMobileDialogOpen(false)}
        >
          <DialogContent>{tooltipContent}</DialogContent>
        </ResponsiveDialog>
      </>
    );
  } else {
    return <Tooltip title={tooltipContent}>{progressBar}</Tooltip>;
  }
}

type MinutesProgressBoxProps = {
  dailyValues: RandomAccessDailyValues;
  day: Date;
  targetMinutes: number;
};

function MinutesProgressBoxWithDayLabel(props: MinutesProgressBoxProps) {
  const { t } = useTranslation(["common"]);

  return (
    <Stack direction="column" spacing={0.5} alignItems="center">
      <Typography fontWeight="bold">{t("common:date.dayOfWeekTiny", { date: props.day })}</Typography>
      <MinutesProgressBox {...props} />
    </Stack>
  );
}

function MinutesProgressBox(props: MinutesProgressBoxProps) {
  const theme = useTheme();

  let label = <MinutesLabel minutes={0} hideZero />;
  let borderColor = theme.palette.info.dark;
  let backgroundColor = theme.palette.background.paper;

  const dayMinutes = props.dailyValues.getValue(props.day);

  if (dayMinutes !== undefined) {
    label = <MinutesLabel minutes={dayMinutes} hideZero />;

    if (dayMinutes !== 0) {
      borderColor = theme.palette[progressColor(props.targetMinutes, dayMinutes)].dark;
      // This is what the ALert component does to get a background color, it's annoyingly not stored in the theem
      // itself.
      backgroundColor = lighten(theme.palette[progressColor(props.targetMinutes, dayMinutes)].light, 0.9);
    }
  }

  return (
    <Stack
      direction="row"
      border={`solid 1px ${borderColor}`}
      borderRadius={"0.25rem"}
      bgcolor={backgroundColor}
      width="3rem"
      height="3rem"
      alignItems="center"
      justifyContent="center"
    >
      {label}
    </Stack>
  );
}

type CurrentDayProgressBarProps = {
  today: Date;
  dailyValues: RandomAccessDailyValues;
  targetMinutes: number;
};

function CurrentDayProgressBarWithLabel(props: CurrentDayProgressBarProps) {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <Stack
      direction="column"
      spacing={0.5}
      alignItems="center"
      justifyContent="start"
      flexGrow={1}
      useFlexGap
    >
      <Typography fontWeight="bold">
        {t("collaborativeCare:progressBar.currentDayLabel", { date: props.today })}
      </Typography>
      <CurrentDayProgressBar {...props} />
    </Stack>
  );
}

function CurrentDayProgressBar(props: CurrentDayProgressBarProps) {
  const theme = useTheme();

  const todayMinutes = props.dailyValues.getValue(props.today);

  if (todayMinutes !== undefined) {
    const todayPercent = todayMinutes / props.targetMinutes;
    const overTarget = todayMinutes > props.targetMinutes;
    const progressValue = overTarget ? 100 : todayPercent * 100;

    const borderColor = theme.palette[progressColor(props.targetMinutes, todayMinutes)].dark;

    return (
      <Stack
        direction="column"
        spacing={0.25}
        width="100%"
        height="3rem"
        position="relative"
        padding="0.5rem"
        paddingBottom="0.25rem"
        border={`solid 1px ${borderColor}`}
        borderRadius={"0.25rem"}
        bgcolor={theme.palette.background.paper}
      >
        {/* The padding on the progress bar makes it take up slightly less space than the threshold labels, so that the
            start/end labels have their middles at the start/end of the bar. */}
        <Box paddingLeft="1rem" paddingRight="1rem" position="relative">
          <LinearProgress
            variant="determinate"
            value={progressValue}
            color={progressColor(props.targetMinutes, todayMinutes)}
            sx={{
              height: "0.5rem",
            }}
          />
          <ProgressMilestonesReward targetMinutes={props.targetMinutes} minutes={todayMinutes} />
        </Box>
        <MinutesRelevantThresholds targetMinutes={props.targetMinutes} />
      </Stack>
    );
  } else {
    return <MinutesDailyProgressError />;
  }
}

function MinutesRelevantThresholds(props: { targetMinutes: number }) {
  // These must be evenly spaced - we're using space-between justification from flexbox as a cheap way to arrange the
  // labels, not actually positioning them based on value.
  const thresholds = useIsMobile() ? [0, 0.5, 1] : [0, 0.25, 0.5, 0.75, 1];

  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      {thresholds.map((t) => (
        <MinutesLabel key={t.toString()} minutes={props.targetMinutes * t} />
      ))}
    </Stack>
  );
}

type ProgressMilestonesRewardProps = {
  targetMinutes: number;
  minutes: number;
};

function ProgressMilestonesReward(props: ProgressMilestonesRewardProps) {
  const { t } = useTranslation(["collaborativeCare"]);

  // Managing the animation state to get the animations to appear and then disappear automatically is a little tricky.
  // First, we figure out what badge we should show
  const rewardBucket =
    props.minutes > props.targetMinutes
      ? "over-target"
      : props.minutes > props.targetMinutes * 0.7
      ? "on-track"
      : props.minutes > props.targetMinutes * 0.35
      ? "halfway"
      : null;

  // Then, we compare that to what badge we were showing in the last render. Note that the first render will always
  // have a previous of undefined, and we only want to pop a badge if the progress changes while the progress bar is
  // mounted, so don't count a fake "change" on the first render.
  const previousBucket = usePrevious(rewardBucket);
  const bucketChanged = previousBucket !== undefined && rewardBucket !== previousBucket;

  // Then, if the bucket changed between renders, turn temporary animations on, and 2000 millis later turn them back
  // off. The timout here must be longer than 1000 milliseconds, since that's the duration of the animation itself.
  const [temporaryAnimationActive, setTemporaryAnimationActive] = React.useState(false);
  useEffectSimpleCompare(() => {
    if (bucketChanged) {
      setTemporaryAnimationActive(true);
      setTimeout(() => setTemporaryAnimationActive(false), 2000);
    }
  }, [bucketChanged]);

  return (
    // All three badges are always mounted so they animate in and out cleanly rather than popping in/out.
    <>
      <RewardBadge
        in={rewardBucket === "halfway" && temporaryAnimationActive}
        left={"35%"}
        color="warning"
        label={t("collaborativeCare:progressBar.milestones.halfway")}
        icon={<DirectionsRun color="warning" />}
      />
      <RewardBadge
        in={rewardBucket === "on-track" && temporaryAnimationActive}
        left={"70%"}
        color="success"
        label={t("collaborativeCare:progressBar.milestones.onTrack")}
        icon={<ThumbUpOffAltSharp color="success" />}
      />
      <RewardBadge
        // Ths badge ignores the temporary animation flag because we want it to persist and show the current value.
        in={rewardBucket === "over-target"}
        right={"-3rem"}
        color="success"
        label={<MinutesLabel minutes={props.minutes} />}
        icon={<CelebrationOutlined color="success" />}
      />
    </>
  );
}

type RewardBadgeProps = {
  in: boolean;
  left?: string;
  right?: string;
  color: "success" | "error" | "warning";
  label: ReactNode;
  icon: ReactNode;
};

function RewardBadge(props: RewardBadgeProps) {
  const theme = useTheme();

  return (
    <Grow in={props.in} timeout={1000} appear={false}>
      {/* Extra div because Grow sets the transform css attr on its child, which would override the attr on Paper */}
      <div>
        <Paper
          sx={{
            position: "absolute",
            right: props.right,
            left: props.left,
            top: "-2rem",
            padding: "0.5rem",
            borderRadius: "0.25rem",
            border: `1px solid ${theme.palette[props.color].dark}`,
            transform: "rotate(-10deg)",
            zIndex: theme.zIndex.modal,
          }}
          elevation={3}
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography fontWeight="bold">{props.label}</Typography>
            {props.icon}
          </Stack>
        </Paper>
      </div>
    </Grow>
  );
}

type MinutesDailyProgressCalendarListProps = {
  points: ReadonlyArray<PickTypename<ChartData, "date" | "value">>;
  targetMinutes: number;
};

export function MinutesDailyProgressCalendarList(props: MinutesDailyProgressCalendarListProps) {
  if (props.points.length === 0) {
    return null;
  }

  // These casts are safe because we check that points has at least one value in it, so these will never return undefined.
  const firstDate = props.points[0]?.date as Date;
  const lastDate = props.points[props.points.length - 1]?.date as Date;

  const firstsOfMonths = eachMonthOfInterval({
    start: firstDate,
    end: lastDate,
  });

  const dailyValues = new RandomAccessDailyValues(props.points);

  return (
    // align-items="start" forces each sub-calendar to only take up as much height as it needs, rather than stretching
    // the rows to make four-week months have the same height as five-week months.
    <Stack direction="row" spacing={3} flexWrap="wrap" alignItems="start">
      {firstsOfMonths.map((day) => (
        <MinutesDailyProgressCalendarMonth
          key={day.toISOString()}
          today={day}
          dailyValues={dailyValues}
          targetMinutes={props.targetMinutes}
        />
      ))}
    </Stack>
  );
}

type MinutesDailyProgressCalendarMonthProps = {
  today: Date;
  dailyValues: RandomAccessDailyValues;
  targetMinutes: number;
};

function MinutesDailyProgressCalendarMonth(props: MinutesDailyProgressCalendarMonthProps) {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  // getDay returns day of the week, 0 is Sunday, so this is how many days of padding we need to add to the first row
  // of the grid to get the first day to be the right day of the week.
  const ghostDays = getDay(startOfMonth(props.today));
  const daysOfMonth = eachDayOfInterval({
    start: startOfMonth(props.today),
    end: endOfMonth(props.today),
  });

  // In rems - 3 rem per square times 7 days per week plus 6 gaps between days times half a rem per gap.
  const calendarWidth = 3 * 7 + 0.5 * 6;

  return (
    <Grid container columns={7} spacing={0.5} width={`${calendarWidth}rem`}>
      <Grid item xs={7}>
        <Typography textAlign="center" fontWeight="bold">
          {t("collaborativeCare:progressBar.calendar.title", { date: props.today })}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography textAlign="center" fontWeight="bold">
          {t("collaborativeCare:progressBar.calendar.sunday")}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography textAlign="center" fontWeight="bold">
          {t("collaborativeCare:progressBar.calendar.monday")}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography textAlign="center" fontWeight="bold">
          {t("collaborativeCare:progressBar.calendar.tuesday")}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography textAlign="center" fontWeight="bold">
          {t("collaborativeCare:progressBar.calendar.wednesday")}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography textAlign="center" fontWeight="bold">
          {t("collaborativeCare:progressBar.calendar.thursday")}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography textAlign="center" fontWeight="bold">
          {t("collaborativeCare:progressBar.calendar.friday")}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography textAlign="center" fontWeight="bold">
          {t("collaborativeCare:progressBar.calendar.saturday")}
        </Typography>
      </Grid>
      {range(0, ghostDays).map((i) => (
        <Grid key={`ghost-${i}`} xs={1} item />
      ))}
      {daysOfMonth.map((day) => (
        <Grid key={day.toISOString()} xs={1} item>
          <MinutesProgressBox dailyValues={props.dailyValues} day={day} targetMinutes={props.targetMinutes} />
        </Grid>
      ))}
    </Grid>
  );
}

function progressColor(target: number, actual: number) {
  // 70% threshold taken from implementation targets being considered "on track" at 70%. The 35% threshold is just
  // when you get to half of that.
  if (actual > target * 0.7) {
    return "success";
  } else if (actual > target * 0.35) {
    return "warning";
  } else {
    return "error";
  }
}

/**
 * This takes the raw chart data from the implementation target, and does two transformations to it:
 *   1. Converts it into a hash indexed by the date (really a string representation of the date, because all javascript
 *      hash keys are strings, and we want to be indifferent about the times of different date values). This lets us
 *      pull arbitrary values out without having to scan the list each time, avoiding potential O(n^2) issues when
 *      rendering a calendar.
 *   2. Transforms the cumulative data points to each day's value. This is done at access time rather than constructor
 *      time because the most common way to use this is to not render all the values. Each day's value is the difference
 *      between that day and the previous day, except for the first of the month when targets reset and days that don't
 *      have a previous day (which should just be the first day).
 */
class RandomAccessDailyValues {
  private hash: Record<string, number>;

  public constructor(private cumulativePoints: ReadonlyArray<PickTypename<ChartData, "date" | "value">>) {
    this.hash = {};
    cumulativePoints.forEach((point) => {
      this.hash[this.key(point.date)] = point.value;
    });
  }

  private key(date: Date): string {
    return format(date, "yyyy-MM-dd");
  }

  public getValue(date: Date): number | undefined {
    const dayValue = this.hash[this.key(date)];

    if (dayValue !== undefined) {
      if (isFirstDayOfMonth(date)) {
        return dayValue;
      } else {
        const dayBeforeValue = this.hash[this.key(subDays(date, 1))];
        return dayValue - (dayBeforeValue || 0);
      }
    } else {
      return undefined;
    }
  }
}
