import {
  ContentPaste,
  Schedule,
  SignalCellularAlt1BarRounded,
  SignalCellularAlt2BarRounded,
  SignalCellularAltRounded,
} from "@mui/icons-material";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import {
  CocmBillingAlgorithmCode,
  CocmBillingAlgorithmPowerStrength,
  CocmBillingAlgorithmRule,
  CocmBillingAlgorithmRuleCheck,
  CocmBillingAlgorithmRuleCode,
} from "GeneratedGraphQL/SchemaAndOperations";
import { formatMinutes } from "Shared/MinutesLabel";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

type RuleCodeDetails = Pick<CocmBillingAlgorithmRuleCode, "units"> & {
  code: Pick<CocmBillingAlgorithmCode, "code">;
};

export function BillableUnitExplanation(props: { rule: { ruleCodes: ReadonlyArray<RuleCodeDetails> } }) {
  return props.rule.ruleCodes.map((ruleCode, index) => {
    return (
      <Typography key={index} marginRight="0.5rem">
        <Typography component="span" fontWeight={"bold"}>
          {ruleCode.code.code}
        </Typography>
        &nbsp;x&nbsp;{ruleCode.units}
      </Typography>
    );
  });
}

export function billableUnitExplanationText(ruleCodes: ReadonlyArray<RuleCodeDetails> | undefined) {
  if (!ruleCodes) {
    return "-";
  }

  return ruleCodes
    .map((ruleCode) => {
      return `${ruleCode.code.code} x ${ruleCode.units}`;
    })
    .join(", ");
}

export type BillingRuleNameDetails = Pick<CocmBillingAlgorithmRule, "id" | "priority"> & {
  ruleCodes: ReadonlyArray<RuleCodeDetails>;
};

export function billingRuleName(rule: BillingRuleNameDetails) {
  return billableUnitExplanationText(rule.ruleCodes);
}

// This is a bit of a hack for now, but we need a 'friendlier' name, so:
// - If there's one code, use it
// - Otherwise choose the 99492/99493 and add up the others
export function shortBillingRuleName(rule: BillingRuleNameDetails) {
  if (rule.ruleCodes.length === 1) {
    return rule.ruleCodes[0]?.code.code;
  }

  const primary = rule.ruleCodes.find((x) => x.code.code === "99492" || x.code.code === "99493");

  if (primary) {
    const sum = rule.ruleCodes.reduce((acc, rc) => acc + rc.units, 0);
    return `${primary.code.code}+${sum - 1}`;
  } else {
    return billingRuleName(rule);
  }
}

function FailReason(props: { minutesShort: number | null; reason: CocmBillingAlgorithmRuleCheck }) {
  const { t } = useTranslation(["billing"]);
  const minutesShortWithUnknown: string =
    typeof props.minutesShort === "number" ? formatMinutes(props.minutesShort) : "?";

  switch (props.reason) {
    case CocmBillingAlgorithmRuleCheck.ASSESSMENT_COMPLETED:
      return (
        <Tooltip title={t("billing:rules.anyAssessmentCompleted.required")}>
          <ContentPaste />
        </Tooltip>
      );
    case CocmBillingAlgorithmRuleCheck.MINIMUM_MINUTES:
      return (
        <Tooltip title={t("billing:rules.minimumMinutes.required", { minutes: minutesShortWithUnknown })}>
          <Stack direction="row" alignContent={"center"} spacing={0.25}>
            <Typography component={"span"}>+&nbsp;{minutesShortWithUnknown}</Typography>
            <Schedule />
          </Stack>
        </Tooltip>
      );
    case CocmBillingAlgorithmRuleCheck.NOT_BILLED_99492_OR_99493_ALREADY:
      return <Typography>{t("billing:rules.notBilled99492Or99493Already.required")}</Typography>;
    case CocmBillingAlgorithmRuleCheck.MUST_BE_INITIAL_ENCOUNTER:
      return <Typography>{t("billing:rules.mustBeInitialEncounter.required")}</Typography>;
    case CocmBillingAlgorithmRuleCheck.MUST_BE_SUBSEQUENT_ENCOUNTER:
      return <Typography>{t("billing:rules.mustBeSubsequentEncounter.required")}</Typography>;
  }
}

/**
 * @returns a readable list of explanations as to why the current rule fails
 */
export function FailReasonsExplanation(props: {
  minutesShort: number | null;
  failReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
  disqualifyReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
}) {
  const { minutesShort, failReasonCodes, disqualifyReasonCodes } = props;
  if (
    (!failReasonCodes || failReasonCodes.length === 0) &&
    (!disqualifyReasonCodes || disqualifyReasonCodes.length === 0)
  ) {
    return "";
  }

  const failExplanations = failReasonCodes
    ? failReasonCodes.map((reason, index) => {
        return <FailReason reason={reason} minutesShort={minutesShort} key={index} />;
      })
    : null;

  const disqualifyExplanations = disqualifyReasonCodes
    ? disqualifyReasonCodes.map((reason, index) => {
        return <FailReason reason={reason} minutesShort={minutesShort} key={index} />;
      })
    : null;

  return (
    <Stack direction="row" alignItems={"center"} spacing={0.25}>
      {failExplanations}
      {disqualifyExplanations}
    </Stack>
  );
}

export function failReasonExplanationText(
  minutesShort: number | null,
  failReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null,
  disqualifyReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null,
  t: TFunction<["billing"]>
): string {
  if (
    (!failReasonCodes || failReasonCodes.length === 0) &&
    (!disqualifyReasonCodes || disqualifyReasonCodes.length === 0)
  ) {
    return "";
  }

  const reasons = [...(failReasonCodes || []), ...(disqualifyReasonCodes || [])].map((reason) => {
    if (reason === CocmBillingAlgorithmRuleCheck.MINIMUM_MINUTES) {
      return t("billing:rules.minimumMinutes.required", { minutes: minutesShort });
    } else if (reason === CocmBillingAlgorithmRuleCheck.ASSESSMENT_COMPLETED) {
      return t("billing:rules.anyAssessmentCompleted.required");
    } else {
      return t("billing:rules.notBilled99492Or99493Already.required");
    }
  });

  return reasons.join(", ");
}

export function PowerBar(props: { strength: CocmBillingAlgorithmPowerStrength | null }) {
  const { strength } = props;
  const { t } = useTranslation(["billing"]);

  if (!strength) {
    return null;
  }

  switch (strength) {
    case CocmBillingAlgorithmPowerStrength.HIGH:
      return (
        <Tooltip title={t("billing:prediction.powerBar.high.tooltip")}>
          <Chip
            color="success"
            label={<Typography fontWeight="bold">{t("billing:prediction.powerBar.high.chip")}</Typography>}
            icon={<SignalCellularAltRounded />}
          />
        </Tooltip>
      );
    case CocmBillingAlgorithmPowerStrength.MEDIUM:
      return (
        <Tooltip title={t("billing:prediction.powerBar.medium.tooltip")}>
          <Chip
            color="warning"
            label={<Typography fontWeight="bold">{t("billing:prediction.powerBar.medium.chip")}</Typography>}
            icon={<SignalCellularAlt2BarRounded />}
          />
        </Tooltip>
      );
    case CocmBillingAlgorithmPowerStrength.LOW:
      return (
        <Tooltip title={t("billing:prediction.powerBar.low.tooltip")}>
          <Chip
            color="error"
            label={<Typography fontWeight="bold">{t("billing:prediction.powerBar.low.chip")}</Typography>}
            icon={<SignalCellularAlt1BarRounded />}
          />
        </Tooltip>
      );
  }
}
