import { Badge, Box, Stack, Typography, useTheme, Link as MUILink } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useTestPatientViewability } from "Contexts/TestPatientViewabilityContext";
import { usePatientLinkUrl } from "FeedbackReport/Demographics/DemographicInfo";
import {
  CareEpisode,
  Enrollment,
  EnrollmentMonth,
  EnrollmentMonthBillingRuleResultWinner,
  MonthParams,
  Organization,
  Patient,
  Provider,
  RuleResultDetailsFragment,
  usePatientMonthlyBillingReportQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { EnrollmentMonthBillingRuleResultWinnerId, EnrollmentMonthId, PatientId } from "Lib/Ids";
import { CsvExportableColDefs, CsvExportableTable } from "MDS/Csv";
import Link from "MDS/Link";
import ErrorMessage from "Shared/ErrorMessage";
import { dateToMonth, monthToDate, monthToday } from "Shared/Month";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { DownloadEnrollmentMonthSummaryPdfButton } from "./DownloadEnrollmentMonthSummaryButton";
import { billableUnitExplanationText } from "Billing/BillingRuleHelpers";
import { useQueryStringIdParameter, useQueryStringMonthV2Parameter } from "Shared/QueryStringParameter";
import BillingWinnerDetailsModal from "Billing/BillingWinnerDetailsModal";
import { MinutesLabel } from "Shared/MinutesLabel";

export function MonthlyBillingReport(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <Page browserTitle={t("collaborativeCare:monthlyBillingReport.title")}>
      <Stack direction="column" spacing={1}>
        <Stack direction="row">
          <Typography component="h1" variant="h1" sx={{ fontSize: "2em" }} flexGrow={1}>
            {t("collaborativeCare:monthlyBillingReport.header")}
          </Typography>
        </Stack>
      </Stack>
      <MonthlyBillingReportList />
    </Page>
  );
}

function MonthlyBillingReportList(): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const theme = useTheme();

  const [month, setMonth] = useQueryStringMonthV2Parameter("month", monthToday());

  const [activeWinnerId, setActiveWinnerId] =
    useQueryStringIdParameter<"EnrollmentMonthBillingRuleResultWinner">("winnerId", true);

  const details = activeWinnerId ? (
    <BillingWinnerDetailsModal winnerId={activeWinnerId} onClose={() => setActiveWinnerId(null)} />
  ) : null;

  const testPatientViewAbility = useTestPatientViewability();

  const { remoteData } = apolloQueryHookWrapper(
    usePatientMonthlyBillingReportQuery({
      variables: {
        monthAndYear: month,
        testPatient: testPatientViewAbility,
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => <Typography>{t("common:remoteData.notAsked")}</Typography>,
    Loading: () => <Typography>{t("collaborativeCare:monthlyBillingReport.loading")}</Typography>,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (response) => {
      if (!response.billingEnrollmentMonthBillingRuleResultWinners) {
        return <ErrorMessage message={t("collaborativeCare:monthlyBillingReport.genericQueryError")} />;
      }
      return (
        <Stack direction="column" spacing={1} marginTop={1}>
          <Box width={theme.spacing(15)}>
            <DatePicker
              views={["year", "month"]}
              label={t("collaborativeCare:monthlyBillingReport.fields.yearAndMonth")}
              minDate={new Date("2012-03-01")} // This should be the oldest enrollment for the institute.
              maxDate={new Date()}
              openTo={"month"}
              value={monthToDate(month)}
              onChange={(newValue) => {
                if (newValue) {
                  setMonth(dateToMonth(newValue));
                }
              }}
              sx={{ backgroundColor: theme.palette.background.paper }}
            />
          </Box>
          <MonthyBillingReportTable
            rows={response.billingEnrollmentMonthBillingRuleResultWinners.nodes}
            month={month}
            onRowSelect={setActiveWinnerId}
          />
          {details}
        </Stack>
      );
    },
  });
}

type RowDataType = {
  id: EnrollmentMonthBillingRuleResultWinnerId;
  enrollmentMonthId: EnrollmentMonthId;
  patientId: PatientId;
  patientName: string;
  mrn: string | null;
  dob: string;
  billableMinutes: number;
  providerName: string | undefined;
  providerNpi: string | undefined | null;
  organizationName: string;
  isTest: boolean;
  careManagerName: string | undefined;
  enrolledAt: string;
  monthsEnrolled: number;
  billingMonth: string;
  billableUnits: string;
  // This key exists just to make the type system happy as all columns need to be
  // in the type right now.
  download: null;
  assessmentCompleted: boolean;
};

type MonthlyBillingReportDataGridProps = {
  onRowSelect?: (value: EnrollmentMonthBillingRuleResultWinnerId) => void;
  rows: ReadonlyArray<
    PickTypename<EnrollmentMonthBillingRuleResultWinner, "id"> & {
      winningResult: RuleResultDetailsFragment | null;
      enrollmentMonth: PickTypename<
        EnrollmentMonth,
        "id" | "billableMinutes" | "beginningOfMonth" | "validatedMeasureCompleted"
      > & {
        pcp: Pick<Provider, "orderedName" | "npiNumber"> | null;
        careManager: Pick<Provider, "orderedName"> | null;
        enrollment: PickTypename<Enrollment, "id" | "enrolledAt"> & {
          careEpisode: PickTypename<CareEpisode, "id"> & {
            organization: Pick<Organization, "name">;
            patient: PickTypename<
              Patient,
              "id" | "name" | "legalFirstName" | "legalLastName" | "dob" | "mrn" | "isTest"
            >;
          };
        };
      };
    }
  >;
  month: MonthParams;
};

function MonthyBillingReportTable(props: MonthlyBillingReportDataGridProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common", "patients"]);
  const { onRowSelect } = props;

  const rows: Array<RowDataType> = props.rows.map((resultWinner) => {
    const patient = resultWinner.enrollmentMonth.enrollment.careEpisode.patient;

    const year = resultWinner.enrollmentMonth.beginningOfMonth.getFullYear();
    // We're adding a single month here, because it's 0-indexed, and because it fixes the
    // months enrolled math where the first calendar month is also the 1st and not the 0th.
    // .getMonth() returns an integer between 0-11 for months
    const month = resultWinner.enrollmentMonth.beginningOfMonth.getMonth() + 1;
    let monthsEnrolled = year * 12 - resultWinner.enrollmentMonth.enrollment.enrolledAt.getFullYear() * 12;
    monthsEnrolled -= resultWinner.enrollmentMonth.enrollment.enrolledAt.getMonth();
    monthsEnrolled += month;

    const calculateLegalName = (patient.legalLastName || "")
      .concat(", ")
      .concat(patient.legalFirstName || "");

    return {
      id: resultWinner.id, // Datagrid seems to require an id.
      enrollmentMonthId: resultWinner.enrollmentMonth.id,
      patientId: patient.id,
      patientName: calculateLegalName,
      mrn: patient.mrn,
      dob: patient.dob ? t("common:date.tiny", { date: patient.dob }) : "",
      billableMinutes: resultWinner.enrollmentMonth.billableMinutes,
      providerName: resultWinner.enrollmentMonth.pcp?.orderedName,
      providerNpi: resultWinner.enrollmentMonth.pcp?.npiNumber,
      organizationName: resultWinner.enrollmentMonth.enrollment.careEpisode.organization.name,
      careManagerName: resultWinner.enrollmentMonth.careManager?.orderedName,
      enrolledAt: t("common:date.tiny", { date: resultWinner.enrollmentMonth.enrollment.enrolledAt }),
      monthsEnrolled: monthsEnrolled,
      isTest: patient.isTest,
      billingMonth: `${month}/01/${year}`, // We're dealing with zero indexed before this.
      billableUnits: resultWinner.winningResult
        ? billableUnitExplanationText(resultWinner.winningResult.rule.ruleCodes)
        : t("collaborativeCare:monthlyBillingReport.notEligible"),
      download: null,
      assessmentCompleted: resultWinner.enrollmentMonth.validatedMeasureCompleted,
    };
  });

  const columns: CsvExportableColDefs<RowDataType> = React.useMemo(() => {
    return [
      {
        headerName: "#",
        rowNumber: true,
      },
      {
        field: "patientName",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.patientName"),
        renderCell: (row) => {
          const url = usePatientLinkUrl(row.patientId);
          return (
            <Badge
              badgeContent={t("patients:referenceHeader.testPatient")}
              invisible={!row.isTest}
              color="success"
            >
              <Link to={url}>{row.patientName}</Link>
            </Badge>
          );
        },
        minWidth: 175,
        sortable: true,
        defaultSort: true,
      },
      {
        field: "mrn",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.mrn"),
        sortable: true,
      },
      {
        field: "dob",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.dob"),
        sortable: true,
      },
      {
        field: "providerName",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.providerName"),
        minWidth: 175,
        sortable: true,
      },
      {
        field: "providerNpi",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.providerNpi"),
        sortable: true,
      },
      {
        field: "careManagerName",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.careManagerName"),
        minWidth: 175,
        sortable: true,
      },
      {
        field: "organizationName",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.organizationName"),
        minWidth: 175,
        sortable: true,
      },
      {
        field: "enrolledAt",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.enrolledAt"),
        sortable: true,
      },
      {
        field: "assessmentCompleted",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.assessmentCompleted"),
        minWidth: 150,
        sortable: true,
      },
      {
        field: "monthsEnrolled",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.monthsEnrolled"),
        sortable: true,
      },
      {
        field: "billingMonth",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.billingMonth"),
        minWidth: 100,
        sortable: true,
      },
      {
        field: "billableMinutes",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.billableMinutes"),
        sortable: true,
        renderCell: (row) => {
          return <MinutesLabel minutes={row.billableMinutes} />;
        },
      },
      {
        field: "billableUnits",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.billableUnits"),
        minWidth: 100,
        sortable: true,
        renderCell: (row) => {
          if (onRowSelect) {
            return <MUILink onClick={() => onRowSelect(row.id)}>{row.billableUnits}</MUILink>;
          } else {
            return row.billableUnits;
          }
        },
      },
      {
        field: "download",
        disableExport: true,
        headerName: t("collaborativeCare:monthlyBillingReport.fields.pdf"),
        sortable: false,
        renderCell: (row) => {
          return (
            <DownloadEnrollmentMonthSummaryPdfButton
              enrollmentMonthId={row.enrollmentMonthId}
              disabled={false}
            />
          );
        },
      },
    ];
  }, []);

  const csvFileName = `${t("collaborativeCare:monthlyBillingReport.title")} - ${props.month.month}-${
    props.month.year
  }.csv`;

  return <CsvExportableTable rows={rows} columns={columns} filename={csvFileName} />;
}
