import {
  CocmBillingAlgorithmRuleCheckValue,
  EntityTreeNodeParams,
  EstimatedBillingCumulativeEffortDatum,
  ImplementationTargetReportGraphDataUnit,
  IntegerRange,
  MonthParams,
  useEstimatedBillingCumulativeEffortQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React from "react";
import { useTranslation } from "react-i18next";
import { Axis, Grid as XYGrid, AnimatedLineSeries, Tooltip, XYChart } from "@visx/xychart";
import { AxisScaleOutput } from "@visx/axis";
import { ScaleConfig } from "@visx/scale";
import { valueIsSecretlyNull } from "type-utils";
import { DEFAULT_CHART_THEME } from "Shared/GraphTheme";
import { TFunction } from "i18next";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { Box, Card, CardContent, CardHeader, Grid, Stack, Typography, styled } from "@mui/material";
import Spinner from "Shared/Spinner";
import ErrorMessage from "Shared/ErrorMessage";
import { formatMoney } from "Shared/Money";
import { formatPercent } from "Shared/formatters";
import { CocmBillingAlgorithmRuleId, EnrollmentMonthBillingRuleResultWinnerId } from "Lib/Ids";
import { formatValueFromUnitClass } from "Implementation/ImplementationTargetElements";
import { formatMinutes } from "Shared/MinutesLabel";

function seriesLegend(valueType: CocmBillingAlgorithmRuleCheckValue, t: TFunction<["billing"]>) {
  switch (valueType) {
    case CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE:
      return t("billing:effort.legends.rate");
    case CocmBillingAlgorithmRuleCheckValue.RVUS:
      return t("billing:effort.legends.rvus");
    case CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS:
      return t("billing:effort.legends.valueUnits");
    case CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES:
      return t("billing:effort.legends.billableMinutes");
  }
}

function maxValueForData(
  series: ReadonlyArray<
    Pick<
      EstimatedBillingCumulativeEffortDatum,
      | "cumulativeMinutes"
      | "cumulativeRateCents"
      | "cumulativeRvus"
      | "cumulativeValueUnits"
      | "cumulativeBillableMinutes"
    >
  >,
  valueType: CocmBillingAlgorithmRuleCheckValue
) {
  const lastItem = series[series.length - 1];

  if (!lastItem) {
    return 0;
  }

  switch (valueType) {
    case CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE:
      return lastItem.cumulativeRateCents;
    case CocmBillingAlgorithmRuleCheckValue.RVUS:
      return lastItem.cumulativeRvus;
    case CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS:
      return lastItem.cumulativeValueUnits;
    case CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES:
      return lastItem.cumulativeBillableMinutes;
  }
}

const BorderedBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  border: `thin solid ${theme.palette.dividerLight}`,
  borderRadius: "3px",
}));

function CumulativeMinutesHeadlineStat(props: {
  series: ReadonlyArray<
    Pick<
      EstimatedBillingCumulativeEffortDatum,
      | "cumulativeMinutes"
      | "cumulativeRateCents"
      | "cumulativeRvus"
      | "cumulativeValueUnits"
      | "cumulativeBillableMinutes"
    >
  >;
  valueType: CocmBillingAlgorithmRuleCheckValue;
  minutes: number;
}) {
  const { series, valueType } = props;
  const { t } = useTranslation(["billing"]);
  const matchesMinute = series.find((s) => s.cumulativeMinutes >= props.minutes);

  if (!matchesMinute) {
    return null;
  }

  const valueAtThirty = (() => {
    switch (valueType) {
      case CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE:
        return formatValueFromUnitClass(
          matchesMinute.cumulativeRateCents,
          ImplementationTargetReportGraphDataUnit.MONEY_CENTS
        );
      case CocmBillingAlgorithmRuleCheckValue.RVUS:
        return formatValueFromUnitClass(
          matchesMinute.cumulativeRvus,
          ImplementationTargetReportGraphDataUnit.FLOAT
        );
      case CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS:
        return formatValueFromUnitClass(
          matchesMinute.cumulativeValueUnits,
          ImplementationTargetReportGraphDataUnit.FLOAT
        );
      case CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES:
        return formatValueFromUnitClass(
          matchesMinute.cumulativeBillableMinutes,
          ImplementationTargetReportGraphDataUnit.MINUTES
        );
    }
  })();

  return (
    <BorderedBox>
      <Typography variant="h1" fontSize={"2.5em"} textAlign={"center"}>
        {valueAtThirty}
      </Typography>
      <Typography variant="h3" fontSize={"1.0em"} textAlign={"center"}>
        {t("billing:effort.withinXMinutes", { minutes: formatMinutes(props.minutes) })}
      </Typography>
    </BorderedBox>
  );
}

function CumulativeMinutesGraph(props: {
  series: ReadonlyArray<
    Pick<
      EstimatedBillingCumulativeEffortDatum,
      | "cumulativeMinutes"
      | "cumulativeRateCents"
      | "cumulativeRvus"
      | "cumulativeValueUnits"
      | "cumulativeBillableMinutes"
    >
  >;
  valueType: CocmBillingAlgorithmRuleCheckValue;
  onClick?: (datum: EstimatedBillingCumulativeEffortDatum) => void;
}) {
  const { series, valueType, onClick } = props;
  const { t } = useTranslation(["billing"]);
  const yScale = {
    type: "linear",
    domain: [0, maxValueForData(series, valueType)],
  } as ScaleConfig<AxisScaleOutput>;

  const xAccessor = (d: EstimatedBillingCumulativeEffortDatum) => {
    return d.cumulativeMinutes;
  };

  const yAccessor = (d: EstimatedBillingCumulativeEffortDatum) => {
    if (valueIsSecretlyNull(d)) {
      return null;
    }
    switch (valueType) {
      case CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE:
        return d.cumulativeRateCents;
      case CocmBillingAlgorithmRuleCheckValue.RVUS:
        return d.cumulativeRvus;
      case CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS:
        return d.cumulativeValueUnits;
      case CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES:
        return d.cumulativeBillableMinutes;
    }
  };

  const yFormatter = (d: EstimatedBillingCumulativeEffortDatum) => {
    if (valueIsSecretlyNull(d)) {
      return null;
    }
    switch (valueType) {
      case CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE:
        return formatMoney({ cents: d.cumulativeRateCents, currency: "USD" });
      case CocmBillingAlgorithmRuleCheckValue.RVUS:
        return d.cumulativeRvus.toFixed(2);
      case CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS:
        return d.cumulativeValueUnits.toFixed(2);
      case CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES:
        return formatMinutes(d.cumulativeBillableMinutes);
    }
  };

  const yTickFormatter = (t: number) => {
    switch (valueType) {
      case CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE:
        return formatMoney({ cents: t, currency: "USD" }, 0);
      case CocmBillingAlgorithmRuleCheckValue.RVUS:
      case CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS:
        return t.toFixed(2);
      case CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES:
        return formatMinutes(t);
    }
  };

  const seriesLine = (
    <AnimatedLineSeries
      dataKey={seriesLegend(props.valueType, t)}
      data={[...props.series]}
      yAccessor={yAccessor}
      xAccessor={xAccessor}
    />
  );

  return (
    <>
      <XYChart
        height={300}
        theme={DEFAULT_CHART_THEME}
        xScale={{ type: "linear", paddingInner: 0.5 }}
        yScale={yScale}
        margin={{ top: 20, right: 20, bottom: 40, left: 60 }}
        onPointerUp={
          onClick
            ? (event) => {
                onClick(event.datum as EstimatedBillingCumulativeEffortDatum);
              }
            : undefined
        }
      >
        <Axis orientation="left" numTicks={6} hideAxisLine tickFormat={yTickFormatter} />
        <Axis
          numTicks={5}
          orientation="bottom"
          hideAxisLine
          rangePadding={50}
          label={t("effort.minutes")}
          tickFormat={(value: number) => formatMinutes(value)}
        />
        <XYGrid numTicks={6} strokeDasharray="3 3" />
        {seriesLine}
        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          showSeriesGlyphs
          renderTooltip={({ tooltipData, colorScale }) => {
            if (!tooltipData || !tooltipData.nearestDatum || !colorScale) {
              return <></>;
            }

            const current = tooltipData.nearestDatum.datum as EstimatedBillingCumulativeEffortDatum;

            const entries = Object.entries(tooltipData.datumByKey).map(([key, datum]) => {
              const item = datum.datum as EstimatedBillingCumulativeEffortDatum;
              return (
                <div key={key} style={{ color: colorScale(key) }}>
                  {key} {yFormatter(item)}
                </div>
              );
            });

            const bonusSection =
              current.additionalPercentOfTarget && current.totalPercentOfTarget ? (
                <div>
                  +{formatPercent(current.additionalPercentOfTarget)}=
                  {formatPercent(current.totalPercentOfTarget)} {t("billing:effort.ofTarget")}
                </div>
              ) : null;

            const bonusSection2 =
              typeof current.cumulativeValueWhenCompleted === "number" ? (
                <div>
                  {yTickFormatter(current.cumulativeValueWhenCompleted)} {t("billing:effort.ofTarget")}
                </div>
              ) : null;

            return (
              <div>
                <div>
                  {t("billing:effort.additionalMinutes")}: {current.cumulativeMinutes}
                </div>
                {bonusSection}
                {bonusSection2}
                {entries}
                <div>{t("billing:effort.clickForDetails")}</div>
              </div>
            );
          }}
        />
      </XYChart>
    </>
  );
}

export function BillingCumulativeEffort(props: {
  month: MonthParams;
  monthOfEnrollment: ReadonlyArray<IntegerRange> | null;
  ruleIds: ReadonlyArray<CocmBillingAlgorithmRuleId> | null;
  entityTreeNode: EntityTreeNodeParams;
  valueType: CocmBillingAlgorithmRuleCheckValue;
  excludeDischarged: boolean;
  setActiveWinnerId?: (id: EnrollmentMonthBillingRuleResultWinnerId | null | undefined) => void;
}) {
  const { t } = useTranslation(["billing"]);
  const { setActiveWinnerId } = props;
  const { remoteData } = apolloQueryHookWrapper(
    useEstimatedBillingCumulativeEffortQuery({
      variables: {
        month: props.month,
        entityTreeNode: props.entityTreeNode,
        valueType: props.valueType,
        monthOfEnrollmentRange: props.monthOfEnrollment,
        ruleIds: props.ruleIds,
        stillEnrolled: props.excludeDischarged,
      },
    })
  );

  const content = remoteData.caseOf({
    Success: (result) => {
      if (result.billingEstimatedBillingCumulativeEffort) {
        return (
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <CumulativeMinutesGraph
                series={result.billingEstimatedBillingCumulativeEffort.dataPoints}
                valueType={props.valueType}
                onClick={setActiveWinnerId ? (datum) => setActiveWinnerId(datum.winnerId) : undefined}
              />
            </Grid>
            <Grid item xs={3}>
              <Stack direction="column" spacing={1}>
                <CumulativeMinutesHeadlineStat
                  series={result.billingEstimatedBillingCumulativeEffort.dataPoints}
                  valueType={props.valueType}
                  minutes={120}
                />
                <CumulativeMinutesHeadlineStat
                  series={result.billingEstimatedBillingCumulativeEffort.dataPoints}
                  valueType={props.valueType}
                  minutes={30}
                />
              </Stack>
            </Grid>
          </Grid>
        );
      }

      return t("billing:effort.noDataFound");
    },
    Loading: () => <Spinner />,
    NotAsked: () => <Spinner />,
    Failure: (error) => <ErrorMessage message={error.message} />,
  });

  return (
    <Card>
      <CardHeader title={t("billing:effort.title")} subheader={t("billing:effort.subheader")} />
      <CardContent>{content}</CardContent>
    </Card>
  );
}
